import { Helmet } from "react-helmet";
import IotPagesHeroSection from "../../Components/IotPagesHeroSection";
import IotSolutionSlider from "../../Components/IotPagesSlider/IotPagesSlider";
import "./IotPages.css";
import HowItWorkSection from "../../Components/HowItWorkSection";
import CommunicationTechnology from "../../Components/CommunicationTechnology";
import IotPagesSolutions from "../../Components/IotPagesSolutions";
import DynasysPlatform from "../../Components/DynasysPlatform";
import ComprehensiveTechnology from "../../Components/ComprehensiveTechnology";

export default function CitySense() {
  const teraMonitorSliderData = [
    {
      TerMonitorSolutionImage: "/Images/IotPagesImages/SmartParking.webp",
      TerMonitorSolutionHead: "Smart   <br> Parking",
      TerMonitorSolutionDetail:
        "Parking Slot Management <br> Parking Occupancy",
      SliderLink: "#smart_parking",
    },
    {
      TerMonitorSolutionImage: "/Images/IotPagesImages/SmartSpace.webp",
      TerMonitorSolutionHead: "Smart Space  <br>  Management",
      TerMonitorSolutionDetail:
        "Smart Space Reservation <br>Occupancy Management",
      SliderLink: "#space_management",
    },
  ];

  return (
    <div className="CitySense">
      <Helmet>
        <title>IoT | CitySense</title>
      </Helmet>

      {/* HERO SECTION COMPONENT  */}
      <IotPagesHeroSection
        BannerImage={"/Images/IotPagesImages/citySenseBanner.webp"}
        HeroHeading={"CitySense"}
        HeroText={
          "The CitySense Space Management system is an innovative solution designed to simplify parking for both administrators and drivers using IoT technology.         "
        }
      />

      {/* HOW IT WORK SECTION  */}
      <HowItWorkSection
        WorkImage1={"/Images/IotPagesImages/urbanEfficiency.svg"}
        WorkImage2={"/Images/IotPagesImages/DecisionAnalytics.svg"}
        WorkImage3={"/Images/IotPagesImages/ElevatedSecurity.svg"}
        Work1={"Optimized Urban Efficiency"}
        Work2={"Informed Decisions through Analytics"}
        Work3={"Elevated Security and Convenience"}
        WorkDetails={
          "The CitySense Space Management system is an innovative solution designed to simplify parking for both administrators and drivers using IoT technology. It enables real-time monitoring and management of parking, offering features like instant slot availability, this system enhances operational efficiency and provides a seamless parking experience."
        }
      />

      {/* Solution Included section  */}
      <div className="IotPagesSliderBg">
        <div className="IotPagesSlider">
          <p className="SolutionHead">Solutions Included</p>
          <IotSolutionSlider
            teraMonitorSliderData={teraMonitorSliderData}
            slidesPerView={2}
          />
        </div>
      </div>

      {/* COMMUNICATION TECHNOLOGY  */}
      <CommunicationTechnology />

      {/* IOT PAGE SOLUTIONS */}
      <IotPagesSolutions
      CatalogueLink={"new-catalogues/SmartParking.pdf"}
        Solution_Id={"smart_parking"}
        SolutionImageLeft={"/Images/IotPagesImages/SmartParkingSolution.webp"}
        SolutioHeading={"Smart   <br> Parking"}
        SolutionDetails={
          "With CitySense’s Smart Parking solution, not only can parking spaces be managed and monitored efficiently, but drivers also receive assistance in finding available spots promptly. This system ensures that parking rules are strictly followed and access is given only to authorized vehicles."
        }
      />
      <IotPagesSolutions
        CatalogueLink={"new-catalogues/SmartSpace Management.pdf"}
        Solution_Id={"space_management"}
        SolutioHeading={"Smart Space <br> Management"}
        SolutionDetails={
          "Statistically, underutilized spaces and mismanagement of bookings can lead to significant financial losses and operational inefficiencies for businesses and facilities. <br> <br>CitySense’s Smart Space Management taps into the essence of space utility, giving you the tools to monitor, manage, and maximize every inch."
        }
        SolutionImageRight={"/Images/IotPagesImages/SmartSpaceSolution.webp"}
      />

      {/* DYNASYS PLATFORM  */}
      <DynasysPlatform />

      {/* COMPREHENSIVE TECHNOLOGY  */}
      <ComprehensiveTechnology />
    </div>
  );
}
