import { Helmet } from "react-helmet";
import IotPagesHeroSection from "../../Components/IotPagesHeroSection";
import IotSolutionSlider from "../../Components/IotPagesSlider/IotPagesSlider";
import "./IotPages.css";
import HowItWorkSection from "../../Components/HowItWorkSection";
import CommunicationTechnology from "../../Components/CommunicationTechnology";
import IotPagesSolutions from "../../Components/IotPagesSolutions";
import DynasysPlatform from "../../Components/DynasysPlatform";
import ComprehensiveTechnology from "../../Components/ComprehensiveTechnology";

export default function EcoClean() {
  const teraMonitorSliderData = [
    {
      TerMonitorSolutionImage: "/Images/IotPagesImages/SmartRodent.webp",
      TerMonitorSolutionHead: "Smart Rodent <br> Monitoring",
      TerMonitorSolutionDetail:
        "Smart Rodent MonitoringRodent Monitoring offers real-time notifications and predictive analysis, enabling quick action and proactive measures to minimize health risks and enhance urban hygiene across various sectors.",
      SliderLink: "#smart_rodent_monitoring",
    },
    {
      TerMonitorSolutionImage:
        "/Images/IotPagesImages/SmartWasteManagement.webp",
      TerMonitorSolutionHead: "Smart Waste <br> Management",
      TerMonitorSolutionDetail:
        "Waste Management solutionleverages intelligentscheduling and optimization, contributing to environmental sustainabilityand efficiency inwaste collection across industries and cities of all sizes.",
      SliderLink: "#smart_waste_management",
    },
  ];

  return (
    <div className="EcoClean">
      {/* HERO SECTION COMPONENT  */}
      <IotPagesHeroSection
        PageTitle={"IoT - EcoClean"}
        BannerImage={"/Images/IotPagesImages/EcoCleanBanner.webp"}
        HeroHeading={"EcoClean"}
        HeroText={
          "In an era where urban cleanliness and public health are paramount, EcoClean emerges as a beacon of innovation.         "
        }
      />

      {/* HOW IT WORK SECTION  */}
      <HowItWorkSection
        WorkImage1={"/Images/IotPagesImages/Efficiency.svg"}
        WorkImage2={"/Images/IotPagesImages/EnvironmentalSustainability.svg"}
        WorkImage3={"/Images/IotPagesImages/PublicHealth.svg"}
        Work1={"Efficiency and Responsiveness"}
        Work2={"Environmental Sustainability"}
        Work3={"Enhanced Public Health"}
        WorkDetails={
          "In an era where urban cleanliness and public health are paramount, EcoClean emerges as a beacon of innovation. It focuses on cleanliness applications, seamlessly integrating IoT to provide real-time solutions. From rodent monitoring to intelligent waste management, EcoClean stands as a symbol of innovation and progress in urban management."
        }
      />

      {/* Solution Included section  */}
      <div className="IotPagesSliderBg">
        <div className="IotPagesSlider">
          <p className="SolutionHead">Solutions Included</p>
          <IotSolutionSlider
            teraMonitorSliderData={teraMonitorSliderData}
            slidesPerView={2}
          />
        </div>
      </div>

      {/* COMMUNICATION TECHNOLOGY  */}
      <CommunicationTechnology />

      {/* IOT PAGE SOLUTIONS */}
      <IotPagesSolutions
        CatalogueLink={"new-catalogues/SmartRodent Monitoring.pdf"}
        Solution_Id={"smart_rodent_monitoring"}
        SolutionImageLeft={"/Images/IotPagesImages/SmartyRodentSolution.webp"}
        SolutioHeading={"Smart Rodent <br> Monitoring"}
        SolutionDetails={
          "In the modern urban landscape, rodent control is more than a nuisance; it’s a vital aspect of public health and safety. Introducing EcoClean’s Rodent Monitoring, a cutting-edge solution that combines the power of IoTwith real-time analytics. This innovative approach not only enhances urban cleanliness but also represents a significant stride in technology- driven pest control."
        }
      />
      <IotPagesSolutions
        CatalogueLink={"new-catalogues/SmartWaste Management.pdf"}
        Solution_Id={"smart_waste_management"}
        SolutioHeading={"Smart Waste <br> Management"}
        SolutionDetails={
          "In today’s rapidly urbanizing world, effective waste management is a cornerstone of sustainability and public well-being. Introducing Eco- Clean’s Waste Management, a modern solution that leverages IoT and real-time analytics. This groundbreaking approach not only streamlines waste collection but also symbolizes a new era in environmentally responsible urban management."
        }
        SolutionImageRight={
          "/Images/IotPagesImages/SmartWasteManagementSolution.webp"
        }
      />

      {/* DYNASYS PLATFORM  */}
      <DynasysPlatform />

      {/* COMPREHENSIVE TECHNOLOGY  */}
      <ComprehensiveTechnology />
    </div>
  );
}
