import React from "react";
import MyButton from "../Button/Button";
import Text from "../Text/Text";
import "./Footer.css";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom"; // Ensure you have react-router-dom installed

export default function Footer() {
  const location = useLocation(); // useLocation hook to get current path
  if (location.pathname === "/" || location.pathname === "/iot") {
    return null; // If pathname is "/new_landing", do not render the footer
  }
  return (
    <div className="Footer">
      <div className="SiteLogo">
        <MyButton RouteLink="/" Src="/Images/SiteLogo.svg" ImgWidth="100%" />
        <div className="footercontent">
          <div className="footerItems">
            <div className="col1">
              <MyButton
                RouteLink="/leadership"
                text="Our Leadership"
                FontSize="1.8rem"
                FontWeight="600"
                Margin="0px 50px 0px 0px"
              />
              <MyButton
                RouteLink="/whoweare"
                text="Who We Are"
                FontSize="1.8rem"
                FontWeight="600"
                Margin="20px 50px 0px 0px"
              />
            </div>
            <div className="col1">
              <MyButton
                RouteLink="/iot"
                text="Internet of Things"
                FontSize="1.8rem"
                FontWeight="600"
                Margin="0px 50px 0px 0px"
              />
              <MyButton
                RouteLink="/satellite_communications"
                text="Satellite Communication"
                FontSize="1.8rem"
                FontWeight="600"
                Margin="20px 50px 0px 0px"
              />
            </div>
            <div className="col1">
              <HashLink smooth to="/our_news">
                <MyButton
                  text="Our News"
                  FontSize="1.8rem"
                  FontWeight="600"
                  Margin="0px 50px 0px 0px"
                />
              </HashLink>
            </div>
            <div className="col1">
              <MyButton
                RouteLink="/contactus"
                text="Contact us"
                FontSize="1.8rem"
                FontWeight="600"
                Margin="0px 50px 0px 0px"
              />
              {/* <MyButton RouteLink="/career" text="Careers" FontSize="1.8rem" FontWeight="600" Margin="20px 50px 0px 0px" /> */}
            </div>
          </div>
          <div className="joinus">
            <Text text="Join the Network" Color="#7B7B7B" FontSize="1.6rem" />
            <div className="socialMedia">
              <MyButton
                RouteLink="https://www.linkedin.com/company/dynasysnetworks/"
                Src="/Images/LinkedIcon.svg"
              />
              <MyButton
                RouteLink="https://www.instagram.com/dynasysnetworks/"
                Src="/Images/instagramIcon.svg"
              />
              <MyButton
                RouteLink="https://www.facebook.com/profile.php?id=61554641788076"
                Src="/Images/facebookIcon.svg"
              />
              <MyButton
                RouteLink="https://twitter.com/DynasysNetworks"
                Src="/Images/twiterIcon.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
