import ImageComp from "../../Components/ImageComp/ImageComp";
import Text from "../../Components/Text/Text";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay,Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function IotLandingSlider() {
  const IotSliderData = [
    {
      SlideLink: "/teramonitor",
      SlideHead: "TerraMonitor",
      SlideDetails:
        "Water Monitoring<br>Air Quality Monitoring<br>Asset Monitoring<br>Agriculture Monitoring",
      SlideImage: "/Images/IotPagesImages/Terra MonitorSliderImage.webp",
    },
    {
      SlideLink: "/umeter",
      SlideHead: "UMeter",
      SlideDetails: "Gas Metering<br>Water Metering<br>Power Metering",
      SlideImage: "/Images/IotPagesImages/UMeterSliderImage.webp",
    },
    {
      SlideLink: "/dynalock",
      SlideHead: "DynaLocks",
      SlideDetails: "Smart Locks <br>Smart Parking Locks",
      SlideImage: "/Images/IotPagesImages/DynalockSliderImage.webp",
    },
    {
      SlideLink: "/dynatrace",
      SlideHead: "DynaTrace",
      SlideDetails:
        "Asset Tracking<br>Personnel Tracking<br>Mobile Cold Storage",
      SlideImage: "/Images/IotPagesImages/DynaTraceSliderImage.webp",
    },
    {
      SlideLink: "/citysense",
      SlideHead: "CitySense",
      SlideDetails: "Smart Parking<br>Smart Space Management",
      SlideImage: "/Images/IotPagesImages/CitySenseSliderImage.webp",
    },
    {
      SlideLink: "/dynafleet",
      SlideHead: "DynaFleet",
      SlideDetails: "Fleet Management",
      SlideImage: "/Images/IotPagesImages/DynaFleetSliderImage.webp",
    },
    {
      SlideLink: "/ecoclean",
      SlideHead: "EcoClean",
      SlideDetails: "Smart Rodent Monitoring <br>Smart Waste Management",
      SlideImage: "/Images/IotPagesImages/EcoCleanSliderImage.webp",
    },
    {
      SlideLink: "/intelipark",
      SlideHead: "IntelliPark",
      SlideDetails: "Smart ANPR Access Management ",
      SlideImage: "/Images/IotPagesImages/IntelliPParkSliderImage.webp",
    },
    {
      SlideLink: "/smartsentry",
      SlideHead: "SmartSentry",
      SlideDetails: "Intelligent Security<br>Intelligent Safety",
      SlideImage: "/Images/IotPagesImages/SmartSentrySliderImage.webp",
    },
    
    
  ];

  return (
    <div className="IotLandingSlider">
      <Swiper
        className=""
        modules={[Autoplay,Navigation]}
        spaceBetween={0}
        slidesPerView={4}
        navigation={true} // Enable navigation
        autoplay={{
          delay: 3000,
          disableOnInteraction: true, // Allow autoplay to continue after interaction
        }}
        breakpoints={{
          150: {
            slidesPerView: 1.2,
            spaceBetween: 10,
            slidesPerGroup: 1,
          },
          550: {
            slidesPerView: 2.2,
            spaceBetween: 20,
            slidesPerGroup: 1,
          },
          768: {
            slidesPerView: 2.2,
            spaceBetween: 20,
            slidesPerGroup: 1,
          },
          992: {
            slidesPerView: 3.2,
            spaceBetween: 10,
            slidesPerGroup: 1,
          },
          1200: {
            slidesPerView: 3.2,
            spaceBetween: 10,
            slidesPerGroup: 1,
          },
          1351: {
            slidesPerView: 4,
            spaceBetween: 0,
            slidesPerGroup: 1,
          },
        }}
        onSlideChange={() => console.log("slide changed")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {/* IoT Innovation in Pakistan Accelerated – DynaSys and Pronet ink MoU */}
        {IotSliderData.map((item) => (
          <SwiperSlide>
            <div className="IotLandingSliderLink">
              <a href={item.SlideLink}>
                <div className="IotSliderText">
                  <Text
                    text={item.SlideHead}
                    FontSize={"3rem"}
                    FontWeight={"700"}
                    Color={"black"}
                    Padding={"0% 0% 0% 65px"}
                    Margin={"5rem 0rem 0rem 0rem"}
                    cursor="pointer"
                  />
                  <Text
                    text={item.SlideDetails}
                    FontSize={"1.8rem"}
                    FontWeight={"400"}
                    Color={"black"}
                    Margin={"2rem 0rem 5rem 0rem"}
                    Padding={"0% 0% 0% 65px"}
                     cursor="pointer"
                  />
                </div>
                  <div className="IotSliderImage">
                  <ImageComp Src={item.SlideImage} Width={"100%"} />
                  </div>
              </a>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
