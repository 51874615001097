import React from "react";
import "./SingleNews.css";
import Text from "../Text/Text";
import ImageComp from "../ImageComp/ImageComp";
import { Link } from "react-router-dom";

export default function NewSingleNews({
  newsdate,
  newshead,
  newsdetails,
  newsimage,
  newslink,
  Width,
  ImgWidth,
}) {
  return (
    <div className="NewNewsWraper">
      <Link to={newslink}>
        <div className="New_News_Container">
          <div className="New_News_Image">
            <ImageComp Src={newsimage} Width={ImgWidth} />
          </div>
          <div className="New_NewsText">
            <div className="New_News_Date">
              <Text
                text={newsdate}
                Margin="0px 0px 8px 0px"
                Color="#808080"
                FontSize="1.2rem"
                FontWeight="700"
              />
            </div>
            <div className="NewsHead_Details">
              <p>
                {newshead}
                <span>{newsdetails}</span>
              </p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
