
export default function IotPagesSolutions({
  SolutionImageLeft,
  SolutionImageRight,
  SolutioHeading,
  SolutionDetails,
  CatalogueLink,
  Solution_Id,
  Property1,
  Property2,
  Property3,
  Property4,
  Property5,
  Property6,
  Property7,
  ButtonNone,
}) {
  return (
    <div
      className={`SolutionWrapper ${
        SolutionImageRight ? "SolutionWrapperRight" : ""
      }`}
    >
      <div className="Solution1" id={Solution_Id}>
        {SolutionImageLeft && (
          <div className="SolitionImage">
            <img src={SolutionImageLeft} alt="" />
          </div>
        )}

        <div className="SolitionDetails">
          <p dangerouslySetInnerHTML={{ __html: SolutioHeading }}></p>
          <p dangerouslySetInnerHTML={{ __html: SolutionDetails }}></p>
          {Property1 && (
            <div className="SolutionProperties">
              <p>
                <span>|</span>
                {Property1}
              </p>
              <p>
                <span>|</span>
                {Property2}
              </p>
              {Property3 && (
                <p>
                  <span>|</span>
                  {Property3}
                </p>
              )}
              {Property4 && (
                <p>
                  <span>|</span>
                  {Property4}
                </p>
              )}
              {Property5 && (
                <p>
                  <span>|</span>
                  {Property5}
                </p>
              )}
              {Property6 && (
                <p>
                  <span>|</span>
                  {Property6}
                </p>
              )}
              {Property7 && (
                <p>
                  <span>|</span>
                  {Property7}
                </p>
              )}
            </div>
          )}

          {
            !ButtonNone && <div className="BannerButtons">
            <a className="OurPlatformLink" href={"https://myiotworld.garajcloud.com/auth/root-login"} target="blank">Our Platform</a>
            <a className="CatalougeLink" href={CatalogueLink} target="blank">Learn more</a>
          </div>
          }
          
        </div>
        {SolutionImageRight && (
          <div className="SolitionImage">
            <img src={SolutionImageRight} alt="" />
          </div>
        )}
      </div>
    </div>
  );
}
