import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Satellites from "./Pages/Satellites/Satellites";
import Cybersecurity from "./Pages/Cybersecurity/Cybersecurity";
import Networks from "./Pages/Networks/Networks";
import Surveillance from "./Pages/Surveillance/Surveillance";
import PowerSolutions from "./Pages/PowerSolutions/PowerSolutions";
import Professionalservices from "./Pages/Professional services/Professional services";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Career from "./Pages/Career/Career";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import MobMenu from "./Components/MobMenu/MobMenu";
import PreReleaseWraper from "./Modules/PressReleases/Pages/PressReleaseWraper";
import BoardChairman from "./Modules/LeaderShip/Pages/BoardChairman";
import LeasderShipWraper from "./Modules/LeaderShip/Pages/LeasderShipWraper";
import DisruptXPressRelease from "./Modules/PressReleases/Pages/DisruptXPressRelease";
import ImranFarooqPressRelease from "./Modules/PressReleases/Pages/ImranFarooqPressRelease";
import HamidNawazPressRelease from "./Modules/PressReleases/Pages/HamidNawazPressRelease";
import ImranFarooq from "./Modules/LeaderShip/Pages/ImranFarooqCTO";
import AliCEO from "./Modules/LeaderShip/Pages/AliCEO";
import MousSigningCeremony from "./Modules/PressReleases/Pages/MousSigningCeremony";
import PtclDynasysJoinHands from "./Modules/PressReleases/Pages/PtclDynasysJoinHands";
import DynasysXiplink from "./Modules/PressReleases/Pages/DynasysXiplink";
import InotechAndDynasysCombine from "./Modules/PressReleases/Pages/InotechAndDynasysCombine";
import IotNew from "./Pages/Iot/IotNew";
import DynasysPronetInk from "./Modules/PressReleases/Pages/DynasysPronetInk";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import CareerOpenings from "./Pages/CareerOpenings/CareerOpenings";
import DynasysTsgnJoinForces from "./Modules/PressReleases/Pages/DynasysTsgnJoinForces";
import AzercosmosOfferIotSolution from "./Modules/PressReleases/Pages/AzercosmosOfferIotSolution";
import DynaSysTriumphsTwiceatHBLPasha from "./Modules/PressReleases/Pages/DynaSysTriumphsTwiceatHBLPasha";
import DynaSysNetworksPioneersPakistan from "./Modules/PressReleases/Pages/DynaSysNetworksPioneersPakistan";
import DynasysPaksatInternationalPioneer from "./Modules/PressReleases/Pages/DynasysPaksatInternationalPioneer";
import DynasysApictaAwards from "./Modules/PressReleases/Pages/DynasysApictaAwards";
import AamirBashirJoinsDynaSys from "./Modules/PressReleases/Pages/AamirBashirJoinsDynaSys";
import AamirHeadBu from "./Modules/LeaderShip/Pages/AamirHeadBu";
import TerraMonitor from "./Pages/IotPages/TerraMonitor";
import Umeter from "./Pages/IotPages/Umeter";
import Dynalock from "./Pages/IotPages/Dynalock";
import DynaTrace from "./Pages/IotPages/DynaTrace";
import CitySense from "./Pages/IotPages/CitySense";
import Dynafleet from "./Pages/IotPages/Dynafleet";
import EcoClean from "./Pages/IotPages/EcoClean";
import InteliPark from "./Pages/IotPages/InteliPark";
import SmartSentry from "./Pages/IotPages/SmartSentry";
import NewLanding from "./Pages/Landing/NewLanding";
import IotLanding from "./Pages/IotPages/IotLanding";
import SatelliteCommunications from "./Pages/SatelliteComPages/SatelliteCommunications";
import Flyaway from "./Pages/SatelliteComPages/Flyaway";
import SatcomAntenna from "./Pages/SatelliteComPages/SatcomAntenna ";
import SatcomFlyaway from "./Pages/SatelliteComPages/SatcomFlyaway";
// import ScreenSize from "./Components/ScreenSize/ScreenSize";
import { GlobalProvider } from "./Components/GlobalContext/GlobalContext";
import AzercosmosActivatesFirstIoTSolution from "./Modules/PressReleases/Pages/AzercosmosActivatesFirstIoTSolution";

function App() {
  return (
    <div className="App">
    <GlobalProvider>
    <BrowserRouter>
    {/*  <ScreenSize /> */}
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<NewLanding />} />
          <Route path="/whoweare" element={<AboutUs />} />
          {/* <Route path="/iot" element={<IotNew />} /> */}
          <Route path="/satellites" element={<Satellites />} />
          <Route path="/cybersecurity" element={<Cybersecurity />} />
          <Route path="/networks" element={<Networks />} />
          <Route path="/surveillance" element={<Surveillance />} />
          <Route path="/powersolutions" element={<PowerSolutions />} />
          <Route
            path="/professionalservices"
            element={<Professionalservices />}
          />

          {/* Our News Routes */}
          <Route path="/our_news" element={<PreReleaseWraper />} />
          <Route
            path="/our_news/hamid_nawaz_press_release"
            element={<HamidNawazPressRelease />}
          />
          <Route
            path="/our_news/disrupt_x_press_release"
            element={<DisruptXPressRelease />}
          />
          <Route
            path="/hamid_nawaz_press_release"
            element={<HamidNawazPressRelease />}
          />
          <Route
            path="/disrupt_x_press_release"
            element={<DisruptXPressRelease />}
          />
          <Route
            path="/imran_farooq_press_release"
            element={<ImranFarooqPressRelease />}
          />
          <Route
            path="/our_news/imran_farooq_press_release"
            element={<ImranFarooqPressRelease />}
          />

          {/* DynaSys Networks receives IoT LPWAN License */}

          <Route path="/iot_license" element={<MousSigningCeremony />} />
          <Route
            path="/our_news/iot_license"
            element={<MousSigningCeremony />}
          />

          {/* PTCL & DynaSys Networks Join Hands */}

          <Route path="/ptcl_dynasys" element={<PtclDynasysJoinHands />} />
          <Route
            path="/our_news/ptcl_dynasys"
            element={<PtclDynasysJoinHands />}
          />

          {/* DynaSys Networks and Xiplink Awarded Cellular Backhaul Optimization Project in Central Asia */}
          <Route path="/dynasys_xiplink" element={<DynasysXiplink />} />
          <Route
            path="/our_news/dynasys_xiplink"
            element={<DynasysXiplink />}
          />

          {/* Inotech & DynaSys Combine Forces to Commercialize IoT in Pakistan */}

          <Route
            path="/inotech_dynasys"
            element={<InotechAndDynasysCombine />}
          />
          <Route
            path="/our_news/inotech_dynasys"
            element={<InotechAndDynasysCombine />}
          />

          {/* IoT Innovation in Pakistan Accelerated – DynaSys and Pronet ink MoU */}

          <Route
            path="/dynasys_pronet_ink_mou"
            element={<DynasysPronetInk />}
          />
          <Route
            path="/our_news/dynasys_pronet_ink_mou"
            element={<DynasysPronetInk />}
          />

          {/* // DynaSys and TSGN Join Forces to Accelerate IoT Growth.. */}

          <Route
            path="/dynasys_tsgn_join_forces"
            element={<DynasysTsgnJoinForces />}
          />
          <Route
            path="/our_news/dynasys_tsgn_join_forces"
            element={<DynasysTsgnJoinForces />}
          />

          {/*     // Azercosmos to Offer IoT Solutions Across Its Satcom Network.. */}

          <Route
            path="/azercosmos_offer_iot_solution"
            element={<AzercosmosOfferIotSolution />}
          />
          <Route
            path="/our_news/azercosmos_offer_iot_solution"
            element={<AzercosmosOfferIotSolution />}
          />

            {/* DynaSys Triumphs Twice at HBL P@SHA ICT Awards 2024 */}

            <Route
            path="/dynasys_triumphs_twice_at_hbl_pasha"
            element={<DynaSysTriumphsTwiceatHBLPasha />}
          />
          <Route
            path="/our_news/dynasys_triumphs_twice_at_hbl_pasha"
            element={<DynaSysTriumphsTwiceatHBLPasha />}
          />

          {/* DynaSys Networks Pioneers Pakistan's IoT Future */}

          <Route
            path="/dynasys_networks_pioneers_pakistan's_iot_future"
            element={<DynaSysNetworksPioneersPakistan />}
          />
          <Route
            path="/our_news/dynasys_networks_pioneers_pakistan's_iot_future"
            element={<DynaSysNetworksPioneersPakistan />}
          />

          {/* DynaSys Networks and PakSat International Pioneer.. */}

          <Route
            path="/dynasys_networks_and_paksat_international_pioneer"
            element={<DynasysPaksatInternationalPioneer />}
          />
          <Route
            path="/our_news/dynasys_networks_and_paksat_international_pioneer"
            element={<DynasysPaksatInternationalPioneer />}
          />

          {/* DynaSys Networks AT APICTA AWARDS.. */}

          <Route
            path="/dynasys_networks_at_apicta_awards"
            element={<DynasysApictaAwards />}
          />
          <Route
            path="/our_news/dynasys_networks_at_apicta_awards"
            element={<DynasysApictaAwards />}
          />

          {/* Aamir Bashir Joins DynaSys */}

          <Route
            path="/aamir_bashir_joins_dynasys"
            element={<AamirBashirJoinsDynaSys />}
          />
          <Route
            path="/our_news/aamir_bashir_joins_dynasys"
            element={<AamirBashirJoinsDynaSys />}
          />

          {/* Azercosmos Activates First IoT Solution */}

          <Route
            path="/azercosmos_activates_first_iot_solution"
            element={<AzercosmosActivatesFirstIoTSolution />}
          />
          <Route
            path="/our_news/azercosmos_activates_first_iot_solution"
            element={<AzercosmosActivatesFirstIoTSolution />}
          />





          {/* LeaderShip routes */}
          <Route path="/leadership" element={<LeasderShipWraper />} />
          <Route path="/founder&ceo" element={<AliCEO />} />
          <Route path="/board_chairman" element={<BoardChairman />} />
          <Route path="/chief_technology_officer" element={<ImranFarooq />} />
          <Route path="/head_of_bu" element={<AamirHeadBu />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/available_positions" element={<CareerOpenings />} />
          <Route path="/mobmenu" element={<MobMenu />} />
          {/* <Route path="/videowhite" element={<LandingVideo />} /> */}
          {/* <Route path="/videoblack" element={<LandingVideoblack />} /> */}

          {/* IOT PAGES ROUTES  */}
          <Route path="/iot" element={<IotLanding />} />
          <Route path="/teramonitor" element={<TerraMonitor />} />
          <Route path="/umeter" element={<Umeter />} />
          <Route path="/dynalock" element={<Dynalock />} />
          <Route path="/dynatrace" element={<DynaTrace />} />
          <Route path="/citysense" element={<CitySense />} />
          <Route path="/dynafleet" element={<Dynafleet />} />
          <Route path="/ecoclean" element={<EcoClean />} />
          <Route path="/intelipark" element={<InteliPark />} />
          <Route path="/smartsentry" element={<SmartSentry />} />

          {/* SATELLITE COMMUNICATIONS PAGES ROUTES  */}
          <Route path="/satellite_communications" element={<SatelliteCommunications />} />
          <Route path="/0.9m_flyaway_ku_band" element={<Flyaway />} />
          <Route path="/1.2m_fixed_ku_band" element={<SatcomAntenna />} />
          <Route path="/1.2m_flyaway_ku_band" element={<SatcomFlyaway />} />

        </Routes>
        <Footer />
      </BrowserRouter>
    </GlobalProvider>
      
    </div>
  );
}

export default App;
