import React, { useEffect, useState, useRef  } from "react";
import Text from "../../Components/Text/Text";
import "../../Styles/Landing.css";
import MyButton from "../../Components/Button/Button";
import ImageComp from "../../Components/ImageComp/ImageComp";
import { Helmet } from "react-helmet";
import NewLandingNewsSlider from "../../Components/NewsSlider/NewLandingNewsSlider";
import { FaFacebookSquare } from "react-icons/fa";
import { BsLinkedin } from "react-icons/bs";
import { FaSquareXTwitter } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";
import { GlobalContext } from "../../Components/GlobalContext/GlobalContext";
import { useContext } from "react";
import { GoArrowRight } from "react-icons/go";

export default function NewLanding() {
  const { isMobileMenuOpen } = useContext(GlobalContext); // Get state from context

  const [videoReady, setVideoReady] = useState(false);

  useEffect(() => {
    const videoElement = document.getElementById("headerVideo");

    const handleVideoReady = () => {
      setVideoReady(true);
    };

    if (videoElement) {
      videoElement.addEventListener("canplaythrough", handleVideoReady);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("canplaythrough", handleVideoReady);
      }
    };
    
  }, []);



  return (
    <>
      <div className={`SocialLogosSticky ${isMobileMenuOpen ? "" : "Stickyback"}`}>
        <div className="SocialLogos">
        <a href="https://www.linkedin.com/company/dynasysnetworks/">
            <BsLinkedin />
          </a>
          <a href="https://www.instagram.com/dynasysnetworks/">
            <RiInstagramFill />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61554641788076">
            <FaFacebookSquare />
          </a>
          <a href="https://twitter.com/DynasysNetworks">
            <FaSquareXTwitter />
          </a>
        </div>
      </div>

      <div className="NewLanding">
    {/* Landing page header section */}
    
      <Helmet>
        <title>DynaSys Networks - Ideate. Innovate. Implement</title>
      </Helmet>
      {!videoReady && (
        <div id="preloader">
          <div class="preloaderbor">
            <div class="spinner"></div>
            <div id="preloader-inner" class="preloadercontent">
              <ImageComp Src="DynaSysLoadermdpi.png" Width="60%" />
            </div>
          </div>
        </div>
      )}

      <div className="NewLandingHero">
        <div className="headerSecWrapper">
          <a href="/iot">
            <div className="headerSec">
              <video
                id="headerVideo"
                width="320"
                height="240"
                playsInline
                loop
                autoPlay
                muted
                preload="auto"
              >
                <source src="Images/LandingIoTVideo.mp4" type="video/mp4" />
              </video>
              <div className="headerSecLink">
                <MyButton
                  RouteLink={"/iot"}
                  text={"Internet of Things"}
                  Color={"white"}
                  FontSize={"1.8rem"}
                  FontWeight={"400"}
                />
              </div>
            </div>
          </a>
        </div>
        <div className="headerSecWrapper">
          <a href="/satellite_communications">
            <div className="headerSec">
              <video
                id="headerVideo"
                width="320"
                height="240"
                playsInline
                loop
                autoPlay
                muted
                preload="auto"
              >
                <source
                  src="Images/LandingSateliteVideo.mp4"
                  type="video/mp4"
                />
              </video>
              <div className="headerSecLink">
                <MyButton
                  RouteLink={"/satellite_communications"}
                  text={"Satellite Communications"}
                  Color={"white"}
                  FontSize={"1.8rem"}
                  FontWeight={"400"}
                />
              </div>
            </div>
          </a>
        </div>
        <div className="SocialLogos">
        <a href="https://www.linkedin.com/company/dynasysnetworks/">
            <BsLinkedin />
          </a>
          <a href="https://www.instagram.com/dynasysnetworks/">
            <RiInstagramFill />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61554641788076">
            <FaFacebookSquare />
          </a>
          <a href="https://twitter.com/DynasysNetworks">
            <FaSquareXTwitter />
          </a>
          
        </div>
      </div>

      {/* News and careers section  */}
      <div className="LandingUpdatesWrapper">
        <div className="Platform-Catalogue">
        <Text
            text={"IoT <span>Platform</span> "}
            FontSize={"2.4rem"}
            FontWeight={"800"}
            Color={"black"}
          />
          <Text
            Margin={"15px 0px 0px 0px"}
            Width={"60%"}
            FontSize={"1.6rem"}
            text={
              "Our Platform serves as a comprehensive ecosystem that revolutionizes the way organizations approach IoT."
            }
            Color={"black"}
          />
          <div className="Platform-CatalogueButtons">
          
            <a
              className="CatalogueLink"
              href="/catalogues/IoT Catalogue.pdf"
              target="black"
            >
              Our Catalogue
            </a>
            <a
              className="CatalogueLink"
              href="https://myiotworld.garajcloud.com/auth/root-login"
              target="black"
            >
              Our Platform
            </a>
          </div>
        </div>
        <div className="WhoWeAreBg">
          <Text
            text={"A Realm of Boundless<br> Opportunities"}
            FontSize={"2.4rem"}
            FontWeight={"800"}
            Color={"black"}
          />
          <Text
            text={"Let’s come together and discover more..."}
            Margin={"15px 0px 0px 0px"}
            Width={"60%"}
            FontSize={"1.6rem"}
          />
          <div className="WhoWeAreButton">
            <a className="WhoWeAreButtonLink" href="/leadership">
            Our Leadership
            </a>
            <a className="WhoWeAreButtonLink" href="/whoweare ">
            Who We Are
            </a>
          </div>
        </div>
        <div className="LandingCareer">
          <div className="LandingCareerInner">
          <MyButton
            RouteLink={"/careers"}
            text={"Careers"}
            Color={"black"}
            FontSize={"2.4rem"}
            FontWeight={"800"}
          />
          <Text
            text={"Empowered to make unique contributions"}
            Margin={"15px 0px 0px 0px"}
            Width={"60%"}
            FontSize={"1.6rem"}
          />
          </div>
          
          <div className="LandingCarrerNewOpening">
          <a
              className="NewOpeningButton"
              href="/available_positions"
            >
              New Openings
              <GoArrowRight  size={22} />

            </a>
          </div>
        </div>
        <div className="LandingNewsSlider">
          <NewLandingNewsSlider />
        </div>
      </div>
    </div>


    </>
    
  );
}
