import React from 'react'
import ImageComp from '../../../../Components/ImageComp/ImageComp'
import MyButton from '../../../../Components/Button/Button'
import Text from '../../../../Components/Text/Text'

export default function PressReleaseWrapperEach({Src,SrcMob, PressDate, PressHead, PressDetails, PressLink}) {
  return (

      <div className="Press_Releases_List">
            <div className="press_Release_one">
              <div className="press_Image">
                <ImageComp Src={Src} Radius="15px" />
              </div>
              <div className="press_Image_mobile">
                <ImageComp Src={SrcMob} Margin="0px 0px 30px 0px" Radius="15px" />
              </div>
              <div className="press_release_content">
                <Text
                  text={PressDate}
                  FontWeight="500"
                  Color="#808080"
                  FontSize={"1.8rem"}
                  font
                  Margin="0px 0px 20px 0px"
                />
                <Text
                  text={PressHead}
                  FontWeight="900"
                  FontSize={"2.5rem"}
                  font
                  Margin="20px 0px 20px 0px"
                />
                <Text
                  text={PressDetails}
                  FontWeight="400"
                  FontSize={"2rem"}
                  font
                  Margin="20px 0px 60px 0px"
                />
                <MyButton
                  BGcolor="#f1f1f1"
                  text="Read More.."
                  Border="1px dashed #808080"
                  Radius="5px"
                  FontWeight="700"
                  FontSize="1.5rem"
                  Padding="8px 15px"
                  RouteLink={PressLink}
                />
              </div>
            </div>
          </div>
  )
}
