import React from 'react'

export default function DynasysPlatform() {
  return (
    <div className="DynaSysNetworksPlatform CommunicationTech">
        <div className="CommunicationTechInner">
          <p>DynaSys Networks Platform</p>
          <p>
            All-in-one solution for air quality monitoring applications with
            device management, visualization, evaluation functions and alarming
            via e-mail, SMS and data export via open interfaces or file.
          </p>
        </div>
      </div>
  )
}
