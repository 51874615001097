import IotPagesHeroSection from "../../Components/IotPagesHeroSection";
import IotSolutionSlider from "../../Components/IotPagesSlider/IotPagesSlider";
import "./IotPages.css";
import HowItWorkSection from "../../Components/HowItWorkSection";
import CommunicationTechnology from "../../Components/CommunicationTechnology";
import IotPagesSolutions from "../../Components/IotPagesSolutions";
import DynasysPlatform from "../../Components/DynasysPlatform";
import ComprehensiveTechnology from "../../Components/ComprehensiveTechnology";

export default function DynaTrace() {
  const teraMonitorSliderData = [
    {
      SliderLink:"#asset_tracking",
      TerMonitorSolutionImage: "/Images/IotPagesImages/AssetTracking.webp",
      TerMonitorSolutionHead: "Asset  <br> Tracking",
      TerMonitorSolutionDetail:
        "From equipment to containers, track all your business assets indoor and outdoor efficiently.",
    },
    {
      SliderLink:"#personnel_tracking",
      TerMonitorSolutionImage: "/Images/IotPagesImages/PersolanTracking.webp",
      TerMonitorSolutionHead: "Personnel Tracking",
      TerMonitorSolutionDetail:
        "Keep track of your team’s location in real time for enhanced safety and productivity.",
    },
    {
      SliderLink:"#mobile_cold_storage",
      TerMonitorSolutionImage: "/Images/IotPagesImages/MobileColdStorage.webp",
      TerMonitorSolutionHead: "Mobile Cold Storage",
      TerMonitorSolutionDetail:
        "Monitor your mobile cold storage units to ensure optimal temperature and, thereby, the quality of the stored goods.",
    },
  ];

  return (
    <div className="DynaTrace">

      {/* HERO SECTION COMPONENT  */}
      <IotPagesHeroSection
      PageTitle={"IoT - DynaTrace"}
        BannerImage={"/Images/IotPagesImages/DynatraceBanner.webp"}
        HeroHeading={"DynaTrace"}
        HeroText={
          "DynaTrace app is an advanced IoT solution for indoor and outdoor tracking of assets and individuals."
        }
      />

      {/* HOW IT WORK SECTION  */}
      <HowItWorkSection
        WorkImage1={"/Images/IotPagesImages/LocateAssets.svg"}
        WorkImage2={"/Images/IotPagesImages/PreventLossess.svg"}
        WorkImage3={"/Images/IotPagesImages/optimizedUtilization.svg"}
        Work1={"Locate Assets Instantly"}
        Work2={"Prevent Losses"}
        Work3={"Optimize Asset Usage"}
        WorkDetails={
          "DynaTrace app is an advanced IoT solution for indoor and outdoor tracking of assets and individuals. It provides real-time monitoring and visibility, enhancing organizational operations, reducing costs, and increasing overall efficiency. The app includes features like geofencing, real-time tracking, and alerts to ensure accurate and current asset management."
        }
      />

      {/* Solution Included section  */}
      <div className="IotPagesSliderBg">
        <div className="IotPagesSlider">
          <p className="SolutionHead">Solutions Included</p>
          <IotSolutionSlider
            teraMonitorSliderData={teraMonitorSliderData}
            slidesPerView={3}
          />
        </div>
      </div>

      {/* COMMUNICATION TECHNOLOGY  */}
      <CommunicationTechnology />

      {/* IOT PAGE SOLUTIONS */}
      <IotPagesSolutions
      CatalogueLink={"new-catalogues/Asset Tracking.pdf"}
      Solution_Id={"asset_tracking"}
        SolutionImageLeft={"/Images/IotPagesImages/AssetTrackingSolution.webp"}
        SolutioHeading={"Asset  <br> Tracking"}
        SolutionDetails={
          "Transform your approach to monitoring and managing both movable and immovable assets indoors. With the power of IoT, our solution offers accurate indoor location tracking, granting you full visibility and control over your assets within enclosed spaces."
        }
        Property1={"Indoor Asset Tracking"}
        Property2={"Outdoor Asset Tracking"}
      />
      <IotPagesSolutions
      CatalogueLink={"new-catalogues/Personal Tracking.pdf"}
      Solution_Id={"personnel_tracking"}
        SolutioHeading={"Personnel <br> Tracking"}
        SolutionDetails={
          "DynaTrace’s Personnel Tracking solution brings a new era of workforce management. Whether for tracking staff during working hours, ensuringsafety in high-risk zones, or managing emergency evacuations, our solution provides real-time location information for enhanced safety and productivity."
        }
        SolutionImageRight={"/Images/IotPagesImages/PersonalTracking.webp"}
      />
      <IotPagesSolutions
      CatalogueLink={"new-catalogues/Mobile Cold Storage.pdf"}
      Solution_Id={"mobile_cold_storage"}
        SolutionImageLeft={"/Images/IotPagesImages/MobileColdStorageSolution.webp"}
        SolutioHeading={"Mobile Cold   <br> Storage"}
        SolutionDetails={
          "Mobile Cold Storage solution caters to businesses that require tem- perature-controlled transportation of assets. Using IoT, we ensure the integrity of your temperature-sensitive goods during transit, optimizing the supply chain, and enhancing customer satisfaction."
        }
      />

      {/* DYNASYS PLATFORM  */}
      <DynasysPlatform />

      {/* COMPREHENSIVE TECHNOLOGY  */}
      <ComprehensiveTechnology />
    </div>
  );
}
