import React from "react";
import "./NewsSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Scrollbar, A11y } from "swiper";
import NewSingleNews from "../SingleNews/NewSingleNews";
import Text from "../Text/Text";
import MyButton from "../Button/Button";

// Learning From Cryptopedia Slider

const NewsSliderCarosel = (ImgWidth) => {
  const News = [

    // Azercosmos Activates First IoT Solution on Azerspace- 1 in Partnership with DynaSys Networks
    {
      NewsLink: "/azercosmos_activates_first_iot_solution",
      NewsDate: "Wed 19 March, 2025",
      NewsHeading:
        "Azercosmos Activates First IoT Solution on Azerspace- 1 in Partnership with DynaSys Networks",
      NewsDetails:
        "Azercosmos Activates First IoT Solution on Azerspace- 1 in Partnership with DynaSys Networks",
      NewsImage: "/Images/Azercosmos Activates_press_wrapper_Mobile.webp",
      NewsImageMob: "/Images/Azercosmos Activates_press_wrapper_Mobile.webp",
    },


    // DynaSys Networks Recognized for its Innovation in IoT at APICTA Awards 2024
    {
      NewsLink: "/aamir_bashir_joins_dynasys",
      NewsDate: "Tue 10 Dec, 2024",
      NewsHeading:
        "M. Aamir Bashir Joins DynaSys Networks as Head of BU - Systems Integration",
      NewsDetails:
        "With over 26 years of Telecoms and ICT experience in Defense, M. Aamir Bashir adds to the strategic",
      NewsImage: "/Images/Aamir-Bashir-Joins_press_wrapper_mobile.webp",
    },

    // DynaSys Networks Recognized for its Innovation in IoT at APICTA Awards 2024
    {
      NewsLink: "/dynasys_networks_at_apicta_awards",
      NewsDate: "Sat 7 Dec, 2024",
      NewsHeading: "DynaSys Networks Recognized for its Innovation in IoT at APICTA Awards 2024",
      NewsDetails: "DynaSys Networks, a leading innovator...",
      NewsImage: "/Images/Dynasys_Apicta_Image_press_wrapper_mobile.webp",
    },
    // DynaSys Networks and PakSat International Pioneer..
    {
      NewsLink: "/dynasys_networks_and_paksat_international_pioneer",
      NewsDate: "Fri 29 Nov, 2024",
      NewsHeading: "DynaSys Networks and PakSat International Pioneer IoT over Satellite Networks in Pakistan",
      NewsDetails: "PakSat International, Pakistan’s nationaly...",
      NewsImage: "/Images/DynaSys_PakSat_International_Pioneer_press_wrapper_mobile.webp",
    },
    // DynaSys Networks Pioneers Pakistan's IoT Future
    {
      NewsLink: "/dynasys_networks_pioneers_pakistan's_iot_future",
      NewsDate: "Tue 19 Nov, 2024",
      NewsHeading: "DynaSys Networks Pioneers Pakistan's IoT Future: The Country’s First Commercial IoT LoRaWAN Network Goes Live",
      NewsDetails: "DynaSys Networks, a leading innovator in...",
      NewsImage: "/Images/DynasysNetworkPioneerPressWrapper_mobile.webp",
    },
     

   
  ];
  return (
    <Swiper
      className=" News_Slider"
      modules={[Navigation, Scrollbar, A11y]}
      spaceBetween={0}
      slidesPerView={1}
      // scrollbar={{
      //   draggable: true,
      //   hide: false,
      //   snapOnRelease: true,
      // }}
      navigation
      breakpoints={{
        // Responsive breakpoints
        150: {
          slidesPerView: 1, // Number of slides to show on mobile
          spaceBetween: 10, // Space between slides on mobile
        },
        550: {
          slidesPerView: 1, // Number of slides to show on mobile
          spaceBetween: 20, // Space between slides on mobile
        },
        992: {
          slidesPerView: 1, // Number of slides to show on mobile
          spaceBetween: 10, // Space between slides on mobile
        },
        1351: {
          slidesPerView: 1, // Number of slides to show on mobile
          spaceBetween: 10, // Space between slides on mobile
        },
      }}
      pagination={{
        spaceBetween: 0,
      }}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {/* IoT Innovation in Pakistan Accelerated – DynaSys and Pronet ink MoU */}

      {News.map((item) => (
        <SwiperSlide>
          <NewSingleNews
            newslink={item.NewsLink}
            newsdate={item.NewsDate}
            newshead={item.NewsHeading}
            newsdetails={item.NewsDetails}
            newsimage={item.NewsImage}
            newsimageMob={item.NewsImageMob}
            ImgWidth={"218px"}
            // Width={"50%"}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default function NewLandingNewsSlider() {
  return (
    <div className="NewNewsSlider">
    <MyButton text="Our News" RouteLink="/our_news" FontSize="2.3rem" FontWeight="800" Color="black" Margin="0px 0px 20px 0px" />
      
      <NewsSliderCarosel />
    </div>
  );
}
