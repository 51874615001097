import React from "react";
import "../Styles/PressRelease.css";
import { Helmet } from "react-helmet";
import "../Styles/MousSigningCeremony.css";
import EachPressReleaseComponent from "../Components/EachPressReleaseComponent/EachPressReleaseComponent";

export default function DynasysPronetInk() {
  return (
    <div className="PressRelease">
      <Helmet>
        <title>DynaSys and Pronet ink MoU</title>
      </Helmet>

      <EachPressReleaseComponent
        PressHeading={
          "IoT Innovation in Pakistan Accelerated – DynaSys and Pronet ink MoU"
        }
        PressDate={"Mon 20 May, 2024"}
        PressDetails={"<span>Karachi, Pakistan, May 20, 2024:</span> DynaSys Networks (DynaSys), a leader in the IoT space and Pronet, a renowned system integrator in Pakistan have signed a Memorandum of Understanding (MoU) on IoT during a ceremony held at the National Aerospace Science and Technology Park in Karachi, Pakistan.<br><br>The signing ceremony was jointly chaired by Abdullah Masood Khan, Director, Pronet and Ali Akhtar, Founder and CEO, DynaSys Networks and was attended by senior officials from both organizations.<br><br>Through this MoU, DynaSys and Pronet aim to combine their expertise, resources, and infrastructure to spearhead the education and adoption of IoT use cases in Pakistan. By leveraging DynaSys’ cutting-edge technologies and Pronets depth in systems integration and service provisioning, this partnership aspires to implement innovative solutions that address pressing challenges and unlock new opportunities in the digital landscape of the country underscored by data driven decision-making.<br><br>During the ceremony, Pronet’s Abdullah Masood Khan said, “By fostering a culture of collaboration and co-creation, we are confident that our partnership will yield groundbreaking innovations that have the power to reshape industries, enhance quality of life, and drive sustainable development. Together, we are committed to shaping the future of IoT and paving the way for a more connected, intelligent, and prosperous Pakistan.”<br><br>On the occasion, DynaSys’ Ali Akhtar, stated, “The collaboration between DynaSys and Pronet signifies a shared commitment to harnessing the transformative potential of IoT technologies to drive efficiency, connectivity, and sustainability across various sectors. With ALEF, our powerful IoT platform, driving our innovation, we are well positioned to digitalize entire industries, businesses, and everyday life.”"}
        PressImage={"/Images/DynasysPronetPageImage.webp"}
        PressImageWidth={"auto"}
        PressImageCaption={"Seated: Ali Akhtar, CEO of DynaSys Networks (Right), Abdullah Masood Khan, Director of Pronet (Left) accompanied by officials of Dynasys and Pronet."}
        OldContactNameOne={"DynaSys Networks:"}
        OldContactOneEmailLink={"https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@dynasysnetworks.com"}
        OldContactNameTwo={"Pronet Private Limited:"}
        OldContactTwoEmailLink={"https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@pronet-tech.net"}
      />
      
      
    </div>
  );
}
