import React from 'react'
import ImageComp from './ImageComp/ImageComp'
import Text from './Text/Text'

export default function HowItWorkSection({WorkDetails, WorkImage1, WorkImage2, WorkImage3, Work1, Work2, Work3}) {
  return (
     <div className="HowItWork">
            <div className="workImagesInner">
              <Text text={"How It Works"} FontSize={"2.4rem"} FontWeight={"600"} />
              <Text
                Color={"#505050"}
                FontSize={"2.8rem"}
                FontWeight={"500"}
                Margin={"3rem 0px 7rem 0px"}
                text={WorkDetails}
              />
              <div className="WorkImages">
                <div>
                  <ImageComp Src={WorkImage1} />
                  <Text
                    text={Work1}
                    FontSize={"2.4rem"}
                    FontWeight={"600"}
                    Margin={"2rem 0rem"}
                  />
                </div>
                <div>
                  <ImageComp Src={WorkImage2} />
                  <Text
                    text={Work2}
                    FontSize={"2.4rem"}
                    FontWeight={"600"}
                    Margin={"2rem 0rem"}
                  />
                </div>
                <div>
                  <ImageComp Src={WorkImage3} />
                  <Text
                    text={Work3}
                    FontSize={"2.4rem"}
                    FontWeight={"600"}
                    Margin={"2rem 0rem"}
                  />
                </div>
              </div>
            </div>
          </div>
  )
}
