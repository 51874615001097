import { Helmet } from "react-helmet";
import IotPagesHeroSection from "../../Components/IotPagesHeroSection";
import "./IotPages.css";
import HowItWorkSection from "../../Components/HowItWorkSection";
import CommunicationTechnology from "../../Components/CommunicationTechnology";
import IotPagesSolutions from "../../Components/IotPagesSolutions";
import DynasysPlatform from "../../Components/DynasysPlatform";
import ComprehensiveTechnology from "../../Components/ComprehensiveTechnology";

export default function Dynafleet() {
  return (
    <div className="DynaFleet">
      {/* HERO SECTION COMPONENT  */}
      <IotPagesHeroSection
        PageTitle={"IoT - DynaFleet"}
        BannerImage={"/Images/IotPagesImages/dynafleetBAnner.webp"}
        HeroHeading={"DynaFleet"}
        HeroText={
          "Modern logistics and operational demands require sophisticated fleet management solutions. With DynaFleet, you’re not just tracking vehicles, you’re optimizing entire fleets. "
        }
      />

      {/* HOW IT WORK SECTION  */}
      <HowItWorkSection
        WorkImage1={"/Images/IotPagesImages/ElevatedSecurity.svg"}
        WorkImage2={"/Images/IotPagesImages/optimizedUtilization.svg"}
        WorkImage3={"/Images/IotPagesImages/costEffective.svg"}
        Work1={"Enhanced Safety"}
        Work2={"Optimized Utilization"}
        Work3={"Reduced Costs"}
        WorkDetails={
          "Modern logistics and operational demands require sophisticated fleet management solutions. With DynaFleet, you’re not just tracking vehicles, you’re optimizing entire fleets. Connect everything from light vehicles to heavy machinery, monitor driver behavior, and manage tasks effortlessly. With real-time GPS tracking, DynaFleet puts you in the driver’s seat of every vehicle in your fleet."
        }
      />

      {/* COMMUNICATION TECHNOLOGY  */}
      <CommunicationTechnology />

      {/* IOT PAGE SOLUTIONS */}
      <IotPagesSolutions
      CatalogueLink={"new-catalogues/DynaFleet.pdf"}
      Solution_Id={"fleet_management"}
        SolutionImageLeft={
          "/Images/IotPagesImages/fleetManagemnetSolution.webp"
        }
        SolutioHeading={"Fleet <br> Management"}
        SolutionDetails={
          "Fleet management is a cloud-based fleet management software with IoT technology that helps businesses manage their vehicles and drivers more efficiently."
        }
      />

      {/* DYNASYS PLATFORM  */}
      <DynasysPlatform />

      {/* COMPREHENSIVE TECHNOLOGY  */}
      <ComprehensiveTechnology />
    </div>
  );
}
