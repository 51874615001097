import React from 'react'

export default function CommunicationTechnology() {
  return (
    <div className="CommunicationTech">
        <div className="CommunicationTechInner">
          <p>Communication Technology</p>
          <p>
            Seamless integration with an extensive array of LPWAN technologies
            and cellular networks
          </p>
          <div className="CommunicationImages">
            <div><img src="/Images/IotPagesImages/sigfox.svg" alt="" /></div>
            <div><img src="/Images/IotPagesImages/lorawan.svg" alt="" /></div>
            <div><img src="/Images/IotPagesImages/nbiot.svg" alt="" /></div>
            <div><img src="/Images/IotPagesImages/blurthoot.svg" alt="" /></div>
            <div><img src="/Images/IotPagesImages/4G.svg" alt="" /></div>
            <div><img src="/Images/IotPagesImages/5G.svg" alt="" /></div>
          </div>
        </div>
      </div>
  )
}
