import { Helmet } from "react-helmet";
import MyButton from "./Button/Button";
import ImageComp from "./ImageComp/ImageComp";
import Text from "./Text/Text";

export default function IotPagesHeroSection({BannerImage, HeroHeading, HeroText, PageTitle}) {
  return (
    <>
      <Helmet>
        <title>{PageTitle}</title>
      </Helmet>
      <div className="BannerImage">
            {/* <ImageComp
              Width={"100%"}
              Src={BannerImage}
            /> */}
            <div className="BannerContentOuter">
              <div className="BannerContent">
                <Text
                  text={HeroHeading}
                  FontSize={"7rem"}
                  Color={"white"}
                  FontWeight={"600"}
                />
                <Text
                  text={HeroText}
                  FontSize={"3rem"}
                  Color={"white"}
                  FontWeight={"400"}
                  Margin={"2rem 0rem 4rem 0rem"}
                />
                <div className="BannerButtons">
                  <MyButton
                    RouteLink={"https://myiotworld.garajcloud.com/auth/root-login"}
                    text={"Our Platform"}
                    Margin={"0% 0% 2% 0%"}
                    BGcolor={"#F3F3F3"}
                    Color={"black"}
                    Padding={"4% 2%"}
                    Radius={"8px"}
                    FontWeight={"400"}
                    Width={"200px"}
                    FontSize={"1.8rem"}
                    Border={"1px solid white"}
                    target={"blank"}

                  />
                  <MyButton
                    RouteLink={"/contactus"}
                    text={"Contact Us"}
                    Margin={"0% 0% 2% 15px"}
                    BGcolor={"black"}
                    Color={"white"}
                    Padding={"4% 2%"}
                    Radius={"8px"}
                    FontWeight={"400"}
                    Width={"200px"}
                    FontSize={"1.8rem"}
                    Border={"1px solid #C6C6C6"}
                  />
                </div>
              </div>
            </div>
          </div>
    </>
    
  )
}
