import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import SingleNews from "../SingleNews/SingleNews";
import { HashLink } from "react-router-hash-link";

export default function IotSolutionSlider({
  teraMonitorSliderData,
  slidesPerView,
  SliderLink
}) {
  return (
    <div>
      <Swiper
        className=" News_Slider"
        modules={[Navigation, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={slidesPerView}
        navigation
        breakpoints={{
          // Responsive breakpoints
          150: {
            slidesPerView: 1.1, // Number of slides to show on mobile
            spaceBetween: 10, // Space between slides on mobile
          },
          550: {
            slidesPerView: 2.5, // Number of slides to show on mobile
            spaceBetween: 20, // Space between slides on mobile
          },
          992: {
            slidesPerView: 3, // Number of slides to show on mobile
            spaceBetween: 10, // Space between slides on mobile
          },
          1351: {
            slidesPerView: slidesPerView, // Number of slides to show on mobile
            spaceBetween: 50, // Space between slides on mobile
          },
        }}
        pagination={{
          spaceBetween: 0,
        }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {/* IoT Innovation in Pakistan Accelerated – DynaSys and Pronet ink MoU */}
        {teraMonitorSliderData.map((item) => (
          <SwiperSlide>
            <HashLink smooth to={item.SliderLink}>
              <div className="SolutionContent">
                <img src={item.TerMonitorSolutionImage} alt="" />
                <div className="SolutionDetails">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item.TerMonitorSolutionHead,
                    }}
                  ></p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item.TerMonitorSolutionDetail,
                    }}
                  ></p>
                </div>
              </div>{" "}
            </HashLink>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
