import React from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";
import ImageComp from "../../../Components/ImageComp/ImageComp";
import Text from "../../../Components/Text/Text";
import Footer from "../../../Components/Footer/Footer";
import { Helmet } from "react-helmet";

export default function BoardChairman() {
  return (
    <div className="LeaderShip">
      <Helmet>
        <title>Chairman of the Board</title>
      </Helmet>
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            BannerText="Our Leadership "
            Src="/Images/leadershopbanner.webp"
            text="About Us <span>/</span>"
            text1="Our Leadership "
            RouteLink="/#aboutus"
            RouteLink1="/leadership"
            Color2="black"
            Color1="#6C6C6C"
          />

          <div className="leadershipdetails">
            <Text text="Hamid Nawaz " FontSize="4rem" FontWeight="700" />
            <Text
              text="Chairman of the Board"
              FontSize="1.8rem"
              FontWeight="700"
            />
          </div>
          <div className="leadershipdetails2">
            <div className="leadershipImageMob">
              <ImageComp
                Src="/Images/hamidNawazchairmanpage.webp"
                Radius="120px"
              />
            </div>
            <div className="leaderhsipconent">
              <Text
                text="Hamid is a seasoned technology executive and globally recognized thought leader in the satellite communications industry. Graduating as Telecommunications Engineer, he started his career as a Research & Development Engineer in 1996. He has a highly successful leadership experience of system design, business development & sales, operations management, project management & product management in large multinationals, government, private equity as well as start-ups."
                Color="black"
              />
              <Text
                text="Prior to joining DynaSys, Hamid was spearheading the business in Middle East and Central Asia for SES, the Luxembourg based satellite operator. Hamid has also held leadership roles at Supernet, the Pakistan based systems integrator and service provider, where he was the COO and at National Data Base and Registration Authority (NADRA), one of Pakistan’s largest database management institutions, where he was the Head of Networks."
                Margin="35px 0px"
                Color="black"
              />
              <Text
                text="Hamid has been a regular speaker at the global and regional satellite and space industry events and has been featured in the Press Releases by equipment manufacturers, satellite operators and satellite service providers for more than a decade. His work as the Project Manager for the region’s first International Civil Aviation Organization (ICAO) recognized Machine Readable Passport earned him Pakistan's National IT Excellence Award.  "
                Color="black"
                Margin="35px 0px"
              />
            </div>
            <div className="leadershipImage">
              <ImageComp
                Src="/Images/hamidNawazchairmanpage.webp"
                Radius="120px"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="borderSec">
        <div className="border"></div>
      </div>
        
    </div>
  );
}
