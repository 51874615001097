import React from "react";
import IotPagesHeroSection from "../../Components/IotPagesHeroSection";
import Text from "../../Components/Text/Text";
import ImageComp from "../../Components/ImageComp/ImageComp";

export default function SatcomAntenna() {
  const Features = [
    {
      featureImage: "/Images/SatelliteCommunicationsImages/Feature1.webp",
      featuresName: "Portable - 04 Piece Reflector",
    },
    {
      featureImage: "/Images/SatelliteCommunicationsImages/feature7.webp",
      featuresName: "Excellent RF Efficiency & Accurate Satellite Pointing",
    },
    {
      featureImage: "/Images/SatelliteCommunicationsImages/feature3.webp",
      featuresName: "Easy to Install (< 10 min)",
    },
    {
      featureImage: "/Images/SatelliteCommunicationsImages/featureCost.webp",
      featuresName: "Cost Effective Solution (1/5th of Foreign OEM Cost) ",
    },
    {
      featureImage: "/Images/SatelliteCommunicationsImages/feature4.webp",
      featuresName: "Stable Mechanical Structure",
    },
    {
      featureImage: "/Images/SatelliteCommunicationsImages/feature3.webp",
      featuresName: "Light Weight & Robust",
    },
    {
      featureImage: "/Images/SatelliteCommunicationsImages/Features.webp",
      featuresName: "Offset Feed Assembly for High RF Gain",
    },
    {
      featureImage: "/Images/SatelliteCommunicationsImages/Features.webp",
      featuresName: "ITU-Compliant Product",
    },
    {
      featureImage: "/Images/SatelliteCommunicationsImages/Features.webp",
      featuresName: "Designed for Field Applications",
    },
  ];

  const SpecificationRF = [
    {
      FeatureName: "Frequency Range (Ku Band)",
      FeatureValue: "Tx = 13.75 – 14.5 GHz <br> Rx = 10.70 – 12.75 GHz"
    },
    {
      FeatureName: "Antenna Gain (Simulated)",
      FeatureValue: "Tx = 42.3 dB @ 14.5 GHz <br> Rx = 41.7 dB @ 12.5 GHz"
    },
    {
      FeatureName: "Beam Width (-3 dB)",
      FeatureValue: "Tx = 0.8° <br> Rx = 1.0°"
    },
    {
      FeatureName: "G/T @ mid-band and 20° EL",
      FeatureValue: "21.63 dB/K (12.5GHz, 70k LNA)"
    },
    {
      FeatureName: "VSWR",
      FeatureValue: "Tx: 1.07:1 <br> Rx: 1.37:1"
    },
    {
      FeatureName: "Side Lobe Performance",
      FeatureValue: "ITU-R S.580-6"
    },
    {
      FeatureName: "Tx to Rx Isolation",
      FeatureValue: "≥ 60 dB"
    },
    {
      FeatureName: "Cross-Pol Isolation",
      FeatureValue: "≥ 30 dB"
    },
    {
      FeatureName: "Power Handling Capability",
      FeatureValue: "1 KW per port"
    },
    {
      FeatureName: "Feed Interface",
      FeatureValue: "WR-75"
    },
    {
      FeatureName: "Feed Insertion Loss",
      FeatureValue: "Tx = 0.2 dB <br> Rx = 0.4 dB"
    },
    {
      FeatureName: "Polarization",
      FeatureValue: "Linear Orthogonal"
    },
  ];

  const MechanicalSpecification = [
    {
      FeatureName: "Axis Travel",
      FeatureValue: "Azimuth = -180⁰ to +180⁰ <br> Elevation = 0⁰ to +65⁰"
    },
    {
      FeatureName: "Antenna Type",
      FeatureValue: "Off-set"
    },
    {
      FeatureName: "Reflector Material",
      FeatureValue: "Composite (Glass Fiber Reinforced)"
    },
    {
      FeatureName: "Antenna Size",
      FeatureValue: "1.2 m"
    },
    {
      FeatureName: "Mount Type",
      FeatureValue: "Antenna Mast"
    },
    {
      FeatureName: "Reflector Segments",
      FeatureValue: "01"
    },
    {
      FeatureName: "Color",
      FeatureValue: "White"
    },
    {
      FeatureName: "Surface Accuracy",
      FeatureValue: "0.5mm (R.M.S)"
    },
  ];

  const EnvirenmentSpecification = [
    {
      FeatureName: "Wind Loading",
      FeatureValue: "50 Km/h (Operational)"
    },
    {
      FeatureName: "Temperature Range",
      FeatureValue: "-40⁰ to +60⁰ C"
    },
    {
      FeatureName: "Relative Humidity",
      FeatureValue: "0 to 95 %"
    }
  ];

  return (
    <div className="SatcomAntenna">
      {/* HERO SECTION COMPONENT  */}
      <IotPagesHeroSection
        PageTitle={"SATCOM Antenna"}
        BannerImage={"/Images/SatelliteCommunicationsImages/SatcomAntena.webp"}
        HeroHeading={"1.2m Fixed Ku-band"}
        HeroText={
          "Experience transnational coverage with our extensive satellite network ensuring connectivity in even the most remote and challenging environments worldwide."
        }
      />

      {/* KEY FEATURES  */}
      <div className="OurExpertiseSupport">
        <Text
          text={"Key Features"}
          FontSize={"3rem"}
          FontWeight={"600"}
          Color={"white"}
          TextAlign={"center"}
          Padding={"5% 0% 0% 0%"}
        />
        <div className="KeyFeatures">
          {Features.map((item) => (
            <div>
              <ImageComp Src={item.featureImage} />
              <Text
                text={item.featuresName}
                Color={"white"}
                Margin={"15px 0px"}
              />
            </div>
          ))}
        </div>
      </div>

      {/* APPLICATIONS  */}
      <div className="Applications">
        <ImageComp
          Src={
            "/Images/SatelliteCommunicationsImages/SatcomAntenaApplication.webp"
          }
        />
        <div>
        <Text
            text={"Applications"}
            FontSize={"3rem"}
            FontWeight={"600"}
            Color={"black"}
            TextAlign={"left"}
            Padding={"5% 0% 0% 0%"}
          />
          <div className="SolutionProperties">
          
          <p>
            <span>|</span>Disaster Management
          </p>
          <p>
            <span>|</span>DTH / VOIP Services
          </p>
          <p>
            <span>|</span>Tele Banking / Tele Medicine
          </p>
          <p>
            <span>|</span>Oil and Gas Exploration Sites
          </p>
          <p>
            <span>|</span>Mining Sites
          </p>
        </div>
        </div>
        
      </div>

      {/* TECHNICAL SPECIFICATIONS:  */}
      <div className="TechnicalSpecifications">
        <Text
          text={"Technical Specifications"}
          FontSize={"3rem"}
          FontWeight={"600"}
          Color={"black"}
          TextAlign={"center"}
          Padding={"0% 0% 0% 0%"}
        />
        <div className="SpecificationWrapper">
          <div className="SpecificationInner">
            <Text
              text={"RF Specification"}
              TextAlign={"left"}
              FontWeight={"600"}
              FontSize={"2.4rem"}
            />

            <div className="SpecificationRow">
              {SpecificationRF.map((item) => (
                <div className="EachSpecification">
                  <Text
                    text={item.FeatureName}
                    TextAlign={"left"}
                    FontWeight={"600"}
                    FontSize={"2.2rem"}
                  />
                  <Text
                    text={item.FeatureValue}
                    TextAlign={"left"}
                    FontWeight={"400"}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="SpecificationInner">
            <Text
              text={"Mechanical Specifications"}
              TextAlign={"left"}
              FontWeight={"600"}
              FontSize={"2.4rem"}
            />

            <div className="SpecificationRow">
              {MechanicalSpecification.map((item) => (
                <div className="EachSpecification">
                  <Text
                    text={item.FeatureName}
                    TextAlign={"left"}
                    FontWeight={"600"}
                    FontSize={"2.2rem"}
                  />
                  <Text
                    text={item.FeatureValue}
                    TextAlign={"left"}
                    FontWeight={"400"}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="SpecificationInner">
            <Text
              text={"Environmental Specifications"}
              TextAlign={"left"}
              FontWeight={"600"}
              FontSize={"2.4rem"}
            />

            <div className="SpecificationRow">
              {EnvirenmentSpecification.map((item) => (
                <div className="EachSpecification">
                  <Text
                    text={item.FeatureName}
                    TextAlign={"left"}
                    FontWeight={"600"}
                    FontSize={"2.2rem"}
                  />
                  <Text
                    text={item.FeatureValue}
                    TextAlign={"left"}
                    FontWeight={"400"}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* RADIATION PATTERNS  */}
      <div className="RadiationPatterns">
        <Text
          text={"Radiation Patterns"}
          FontSize={"3rem"}
          FontWeight={"600"}
          Color={"black"}
          TextAlign={"center"}
          Padding={"0% 0% 3% 0%"}
        />
        <div>
          <ImageComp
            Src={"/Images/SatelliteCommunicationsImages/satcomAntenaPattern1.webp"}
          />
          <ImageComp
            Src={"/Images/SatelliteCommunicationsImages/satcomAntenaPattern2.webp"}
          />
        </div>
      </div>
      <div class="borderSec"><div class="border"></div></div>

    </div>
  );
}
