import React from "react";
import "../Styles/PressRelease.css";
import { Helmet } from "react-helmet";
import Text from "../../../Components/Text/Text";
import ImageComp from "../../../Components/ImageComp/ImageComp";
import MyButton from "../../../Components/Button/Button";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";

export default function AamirBashirJoinsDynaSys() {
  return (
    <div className="PressRelease">
      <Helmet>
        <title>Aamir Bashir Joins DynaSys Press Release</title>
      </Helmet>
      
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            text="Media Center   <span>/</span>"
            text1="  Our News"
            // RouteLink="/our_news/hamid_nawaz_press_release"
            RouteLink1="/our_news"
            Color1="#6C6C6C"
            Color2="black"
          />
          <div className="PressReleaseBody">
            <div className="PressReleaseBodyTopText">
              <Text
                text="Muhammad Aamir Bashir Joins DynaSys Networks as Head of Business Unit - Systems Integration"
                FontWeight="900"
                FontSize={"3.5rem"}
                font
                Margin="50px 0px 50px 0px"
              />
              <Text
                text="Tue 10 Dec, 2024"
                FontWeight="500"
                Color="#808080"
                FontSize={"1.8rem"}
                font
                Margin="50px 0px 20px 0px"
              />
              <Text
                text="With over 26 years of Telecoms and ICT experience in Defense, M. Aamir Bashir adds to the strategic depth of the leadership team at DynaSys Networks."
                FontSize={"3.2rem"}
                Margin="0px 0px 50px 0px"
                Padding="0px 150px 0px 0px"
              />
            </div>

            <div className="PressRelease_Img_Sec">
              <div className="Right_Sec_Mob">
                <ImageComp Src={"/Images/AamirBashirPressReleaseImage.webp"} Width="100%" />
              </div>
              <div className="Left_Sec">
                <Text
                  text="<span>10th December 2024 - Rawalpindi, Pakistan - </span> DynaSys Networks announced today that Mr. M. Aamir Bashir has joined the organization as Head of Business Unit - Systems Integration."
                  FontSize="2.1rem" 
                />
                <Text
                  text="Aamir is a celebrated telecommunications and ICT professional with over 26 years of hands-on experience in the defense industry. After graduating from Pakistan's National University of Science and Technology with a degree in Avionics Engineering, Aamir started his career as a Field Engineer in 1998. Aamir also holds a Masters degree in IT, majoring in networks."
                  FontSize="2.1rem"
                  Margin="30px 0px 30px 0px"
                />
                <Text
                  text="His expertise spans various aspects of IT & telecommunications technologies, including LTE/4G, microwave networks, optical fiber networks, satellite communications, network architecture, project management, designing and optimizing network solutions and integrating emerging technologies."
                  FontSize="2.1rem"
                />
              </div>
              <div className="Right_Sec">
                <ImageComp Src={"/Images/AamirBashirPressReleaseImage.webp"} Width="96%" />
              </div>
            </div>
            <Text text="Aamir has a proven track record of driving technological advancements and successfully delivering large scale projects for the defence market." />

            <Text Margin="30px 0px 30px 0px" text="“It is with great joy that I welcome Aamir to DynaSys. I have personally followed his growth as a professional over the past decade and half and I am thrilled to add his panache and skill to our leadership team. With his excellent track record of successfully delivering complex technology projects in the defense industry, Aamir will undoubtedly lead our systems integration business unit to new heights”, stated Ali Akhtar, Founder & CEO, DynaSys Networks." />

            <Text Margin="0px 0px 30px 0px" text="“I am thrilled to join DynaSys Networks' exceptional team, where innovation and expertise converge. The company's pioneering approach to digitization is truly inspiring. As Head of Business Unit - Systems Integration, I am eager to spearhead initiatives that drive growth, foster strategic partnerships, and solidify DynaSys' position as a leading provider of cutting-edge defense solutions,” says M. Aamir Bashir, Head of Business Unit - Systems Integration, DynaSys Networks." />



            <div className="Press_Release_bottom">
              <div className="SiteLinkSec">
                <Text
                  text="For any inquiries, please reach out at"
                  Margin="0px 2px 0px 0px"
                  FontSize="1.8rem"
                />
                <MyButton
                  RouteLink="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@dynasysnetworks.com"
                  FontSize="1.8rem"
                  text="info@dynasysnetworks.com"
                  FontWeight="400"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="borderSec">
        <div class="border"></div>
      </div>
        
    </div>
  );
}
