import { Helmet } from "react-helmet";
import IotPagesHeroSection from "../../Components/IotPagesHeroSection";
import IotSolutionSlider from "../../Components/IotPagesSlider/IotPagesSlider";
import "./IotPages.css";
import HowItWorkSection from "../../Components/HowItWorkSection";
import CommunicationTechnology from "../../Components/CommunicationTechnology";
import IotPagesSolutions from "../../Components/IotPagesSolutions";
import DynasysPlatform from "../../Components/DynasysPlatform";
import ComprehensiveTechnology from "../../Components/ComprehensiveTechnology";

export default function TerraMonitor() {
  const teraMonitorSliderData = [
    {
      SliderLink: "#water_monitoring",
      TerMonitorSolutionImage: "/Images/IotPagesImages/waterMonitoring.png",
      TerMonitorSolutionHead: "Water <br> Monitoring",
      TerMonitorSolutionDetail:
        "Smart Water Quality Monitoring<br> Pressure Monitoring <br> Level Monitoring<br> Water Leakage Detection",
    },
    {
      SliderLink: "#air_qality_monitoring",
      TerMonitorSolutionImage: "/Images/IotPagesImages/airQuality.png",
      TerMonitorSolutionHead: "Air Quality<br> Monitoring",
      TerMonitorSolutionDetail:
        "Indoor Air Quality Monitoring  <br> Outdoor Air Quality Monitoring  <br> Odour Monitoring  <br> Vape Monitoring  <br> Air Pressure Monitoring",
    },
    {
      SliderLink: "#asset_monitoring",
      TerMonitorSolutionImage: "/Images/IotPagesImages/assetMonitoring.png",
      TerMonitorSolutionHead: "Asset<br>   Monitoring",
      TerMonitorSolutionDetail:
        "Cold Storage Monitoring  <br> Noise Monitoring  <br> Agriculture Monitoring  <br> Level Monitoring  <br> Industrial Assets Monitoring  <br> Smoke & Fire Detection Gas Detection",
    },
    {
      SliderLink: "#agriculture_monitoring",
      TerMonitorSolutionImage:
        "/Images/IotPagesImages/agricultureMonitoring.png",
      TerMonitorSolutionHead: "Agriculture<br> Monitoring",
      TerMonitorSolutionDetail:
        "Smart Farms <br> Smart Greenhouse",
    },
  ];

  return (
    <div className="TeraMonitor">

      {/* HERO SECTION COMPONENT  */}
      <IotPagesHeroSection
      PageTitle={"IoT - TeraMonitor"}
        BannerImage={"/Images/IotPagesImages/TerramonitorBanner.png"}
        HeroHeading={"TerraMonitor"}
        HeroText={
          "Empower your facility with TerraMonitor, an advanced IoT solution revolutionizing facility and asset monitoring."
        }
      />

      {/* HOW IT WORK SECTION  */}
      <HowItWorkSection
        WorkImage1={"/Images/IotPagesImages/operationals.svg"}
        WorkImage2={"/Images/IotPagesImages/extended.svg"}
        WorkImage3={"/Images/IotPagesImages/cost.svg"}
        Work1={"Enhanced operational efficiency"}
        Work2={"Extended asset lifetime"}
        Work3={"Reduced facility management costs"}
        WorkDetails={
          "Empower your facility with TerraMonitor, an advanced IoT solution revolutionizing facility and asset monitoring. With state-of-the-art technology and real-time data analysis, TerraMonitor provides unparalleled visibility and empowers you to make informed decisions."
        }
      />

      {/* Solution Included section  */}
      <div className="IotPagesSliderBg">
        <div className="IotPagesSlider">
          <p className="SolutionHead">Solutions Included</p>
          <IotSolutionSlider
            teraMonitorSliderData={teraMonitorSliderData}
            slidesPerView={3.2}
          />
        </div>
      </div>

      {/* COMMUNICATION TECHNOLOGY  */}
      <CommunicationTechnology />

      {/* IOT PAGE SOLUTIONS */}
      <IotPagesSolutions
      CatalogueLink={"new-catalogues/Water Monitoring.pdf"}
      Solution_Id={"water_monitoring"}
        SolutionImageLeft={"/Images/IotPagesImages/WaterSolution.png"}
        SolutioHeading={"Water <br> Monitoring"}
        SolutionDetails={
          "Our IoT solution for water monitoring leverages advanced sensors and connectivity to provide realtime insights into water quality levels, and usage.<br> <br>With this solution, businesses and municipalities can efficiently manage water resources, detect leaks or contamination, and ensure regulatory compliance."
        }
        Property1={"Smart Water Quality Monitoring"}
        Property2={"Pressure Monitoring"}
        Property3={"Level Monitoring"}
        Property4={"Water Leakage Detection"}
      />
      <IotPagesSolutions
      CatalogueLink={"new-catalogues/Air Quality Monitoring.pdf"}
      Solution_Id={"air_qality_monitoring"}
        SolutioHeading={"Air Quality <br> Monitoring"}
        SolutionDetails={
          "Our IoT solution for air quality monitoring employs cutting-edge sensors to continuously monitor and analyze air pollutants, temperature, and humidity levels.<br><br>By providing actionable data, this solution enables individuals, businesses, and cities to improve air quality, enhance public health, and make informed decisions for sustainable urban development."
        }
        SolutionImageRight={"/Images/IotPagesImages/AirQualitySolution.png"}
        Property1={"Indoor Air Quality Monitoring"}
        Property2={"Outdoor Air Quality Monitoring"}
        Property3={"Odour Monitoring"}
        Property4={"Vape Monitoring"}
        Property5={"Air Pressure Monitoring"}
      />
      <IotPagesSolutions
      CatalogueLink={"new-catalogues/Asset Monitoring.pdf"}
      Solution_Id={"asset_monitoring"}
        SolutioHeading={"Asset <br> Monitoring"}
        SolutionDetails={
          "Our IoT solution for industrial asset monitoring is designed to monitor critical parameters such as temperature, humidity, PM (particulate matter) levels, vibrations and more in real-time.<br><br> By utilizing advanced sensors and connectivity, businesses can gain valuable insights into the health and performance of their industrial assets, ensuring optimal operations and minimizing downtime."
        }
        SolutionImageLeft={"/Images/IotPagesImages/AssetSolution.png"}
        Property1={"Cold Storage Monitoring"}
        Property2={"Noise Monitoring"}
        Property4={"Agticulture Monitoring"}
        Property3={"Level Monitoring"}
        Property5={"Industrial Assets Monitoring"}
        Property6={"Smoke & Fire Detection"}
        Property7={"Gas Detection"}
      />
      <IotPagesSolutions
      CatalogueLink={"new-catalogues/Agriculture Monitoring.pdf"}
      Solution_Id={"agriculture_monitoring"}
        SolutioHeading={"Agriculture <br>  Monitoring"}
        SolutionDetails={
          "Our IoT-based Agriculture Monitoring system delivers advanced, real-time insights into soil parameters and environmental metrics, empowering farmers, researchers, and environmentalists with the data they need to make informed decisions for optimal growth and sustainability."
        }
        SolutionImageRight={"/Images/IotPagesImages/AgricultureSolution.png"}
        Property1={"Smart Farms"}
        Property2={"Smart Greenhouse"}
      />

      {/* DYNASYS PLATFORM  */}
      <DynasysPlatform />

      {/* COMPREHENSIVE TECHNOLOGY  */}
      <ComprehensiveTechnology />
    </div>
  );
}
