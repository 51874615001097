import React, { useEffect, useState, useRef } from "react";
import MyButton from "../Button/Button";
import "./Navbar.css";
import { HashLink } from "react-router-hash-link";
import { useLocation, useNavigate } from "react-router";
import searchData from "../SearchData.json";
import { FiSearch } from "react-icons/fi";
import { useContext } from "react";
import { FaArrowRightLong } from "react-icons/fa6";


import { GlobalContext } from "../GlobalContext/GlobalContext";

export default function Navbar() {
  const { isMobileMenuOpen, setMobileMenuOpen } = useContext(GlobalContext);
  const [searchMenu, setSearchMenu] = useState(false);
  const [allowCloseAnimation, setAllowCloseAnimation] = useState(false);

  const toggleSearchMenu = () => {
    if (!searchMenu) {
      // When opening the menu, allow close animation on next toggle
      setAllowCloseAnimation(true);
    }
    setSearchMenu(prevState => !prevState);
  };


  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
    setIsDropdownOpen(false);
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
  };

  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);

  const handleDropdownToggle2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
    setIsDropdownOpen(false);
    setIsDropdownOpen3(false);
  };

  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);

  const handleDropdownToggle3 = () => {
    setIsDropdownOpen3(!isDropdownOpen3);
    setIsDropdownOpen(false);
    setIsDropdownOpen2(false);
  };

  // FOR SEARCH FUNCTIONALITY
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [pages, setPages] = useState(searchData);
  const navigate = useNavigate();
  const location = useLocation();

  const searchRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      searchRef.current && 
      !event.target.closest(".SearchInputField") &&
      !searchRef.current.contains(event.target) &&
      !event.target.closest(".SearchResultWrapper") &&
      !event.target.closest(".SearchButton") &&
      !event.target.closest("button")
    ) {
      setSearchTerm("");
      setSearchInputWidth(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, []);

    // Navigate to selected page
    const handleNavigate = (path) => {
      setSearchTerm(""); // Clear search input
      setFilteredResults([]); // Hide search results
      setSearchInputWidth(false);
      setSearchMenu(false);
      navigate(path);

  
    };


  // Fetch data from JSON file
  useEffect(() => {
    fetch("../SearchData.json") // If inside `public` folder, use this path
      .then((response) => response.json())
      .then((data) => setPages(data))
      .catch((error) => console.error("Error fetching search data:", error));
  }, []);

  // Handle search input change
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.length > 0) {
      const results = pages.filter(
        (page) =>
          page.title.toLowerCase().includes(value.toLowerCase()) ||
          page.content.toLowerCase().includes(value.toLowerCase()) // Search in title and content
      );
      setFilteredResults(results);
    } else {
      setFilteredResults([]);
    }
  };

  // const isBlackNavbar = blackNavbarRoutes.includes(location.pathname);

  const [searchInputWidth, setSearchInputWidth] = useState(false);

  return (
    // <div className={`Navbar ${isBlackNavbar ? "NavbarBlack" : ""} `}>
    <div className="Navbar">
      <div className="SiteLogo">
        <MyButton
          RouteLink="/"
          Src={"/Images/SiteLogo.svg"}
          Width={"100%"}
          ImgWidth={"100%"}
        />
      </div>
      <div className="NavbarItems">
        <div className="items">
          <ul>
            <li className="AboutItem">
              <MyButton
                text="About Us"
                FontSize="2rem"
                FontWeight="600"
                Color={"black"}
              />
              <div className={`aboutDropdown ${isDropdownOpen ? "open" : ""}`}>
                <ul>
                  <li>
                    <div className="aboutItemdropcontent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 384 512"
                      >
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>

                      <MyButton
                        RouteLink="/leadership"
                        text="Our Leadership"
                        FontSize="1.6rem"
                        FontWeight="600"
                        Color="#797979"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="aboutItemdropcontent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 384 512"
                      >
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>
                      <MyButton
                        RouteLink="/whoweare"
                        text="Who We Are"
                        FontSize="1.6rem"
                        FontWeight="600"
                        Color="#797979"
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                      >
                      </svg>
                    </div>
                  </li>
                </ul>
              </div>
            </li>

            <li className="AboutItem">
              <MyButton
                text="Solutions"
                FontSize="2rem"
                FontWeight="600"
                Color={"black"}
              />
              <div className={`aboutDropdown ${isDropdownOpen ? "open" : ""}`}>
                <ul>
                  <li>
                    <div className="aboutItemdropcontent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 384 512"
                      >
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>

                      <MyButton
                        RouteLink="/iot"
                        text="Internet of Things"
                        FontSize="1.6rem"
                        FontWeight="600"
                        Color="#797979"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="aboutItemdropcontent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 384 512"
                      >
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>
                      <MyButton
                        RouteLink="/satellite_communications"
                        text="Satellite Communications"
                        FontSize="1.6rem"
                        FontWeight="600"
                        Color="#797979"
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                      >
                      </svg>
                    </div>

                  </li>
                </ul>
              </div>
            </li>

            <li>
              <MyButton
                RouteLink="/careers"
                text="Careers"
                FontWeight="600"
                Color={"black"}
              />
            </li>
            <li className="AboutItem">
              <MyButton
                text="Media Center"
                FontSize="2rem"
                FontWeight="600"
                Color={"black"}
              />
              <div className={`aboutDropdown ${isDropdownOpen ? "open" : ""}`}>
                <ul>
                  <li>
                    <div className="aboutItemdropcontent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 384 512"
                      >
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>
                      <MyButton
                        RouteLink="/our_news"
                        text="Our News"
                        FontSize="1.6rem"
                        FontWeight="600"
                        Color="#797979"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </li>

            <li>
              <MyButton
                RouteLink="/contactus"
                text="Contact Us"
                FontWeight="600"
                Color={"black"}
              />
            </li>
            <li className="" >
              {/* Search Input */}
              <div className={`SearchInputFieldWrapper ${searchInputWidth ? "SearchButtonWidth" : ""
                } `}>
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleSearch}
                  className="SearchInputField"
                />

              </div>
              {/* Search Dropdown */}

              {searchInputWidth && filteredResults.length > 0 && searchTerm.trim() !== "" && (
                <div className="SearchResultWrapper">
                  {filteredResults.map((result, index) => (
                    <p
                      key={index}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                      onClick={() => handleNavigate(result.path)}
                    >
                      <HashLink smooth to={result.path}>
                        {result.title}
                      </HashLink>
                    </p>
                  ))}
                </div>
              )}
              <button
                className="SearchButton"
                onClick={() => setSearchInputWidth(prev => !prev)} // Toggle the search bar
              >
                <FiSearch
                  size={25}
                  color={"black"}
                />
              </button>
            </li>
          </ul>
        </div>
      </div>

      <button
        className="SearchButtonMobile"
        onClick={toggleSearchMenu} // Toggle the search bar
      >
        <FiSearch
          size={25}
          color={"black"}
        />

      </button>

      {
      <div className={`SearchMenu ${searchMenu ? "SearchMenuHeight" : allowCloseAnimation ? "SearchMenuHide" : ""}`}>
          <div className="closeSearchMenu">
            <MyButton Src={"/Images/SearchMenuClose.svg"} ImgWidth={"35px"} handleClick={toggleSearchMenu} />

            <div className="MobileSearchMenu">
              <div className="SearchInputWrapper">
                <div>
                  <FiSearch
                    size={25}
                    color={"black"}
                  />
                  <input ref={searchRef}
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearch}
                    className="SearchInputField"
                  />
                </div>

                {/* Search Dropdown */}

                {filteredResults.length > 0 && (
                  <div className="SearchResultWrappere">
                    {filteredResults.map((result, index) => (
                      <p
                        key={index}
                        className="p-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => handleNavigate(result.path)}
                      >
                        <HashLink smooth to={result.path}>
                          {result.title}
                        </HashLink>
                      </p>
                    ))}
                  </div>
                )}
                <div className="QuickLinks">
                  <p>quick links</p>
                  <a href="https://myiotworld.garajcloud.com/auth/root-login">
                    <FaArrowRightLong />
                    our platform
                  </a>
                  <a href="/leadership">
                    <FaArrowRightLong />
                    Our Leadership
                  </a>
                  <a href="/satellite_communications">
                    <FaArrowRightLong />
                    Satellite Communications
                  </a>
                  <a href="/iot">
                    <FaArrowRightLong />
                    internet of things
                  </a>
                  <a href="/whoweare">
                    <FaArrowRightLong />
                    Who We Are
                  </a>
                  <a href="/careers">
                    <FaArrowRightLong />
                    Careers
                  </a>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        
      }


      <div className="mobileMenu">

        <input
          type="checkbox"
          id="navi-toggle"
          className="checkbox"
          checked={isMobileMenuOpen}
        />
        <label
          htmlFor="navi-toggle"
          className="button"
          onClick={toggleMobileMenu}
        >
          <span className="icon"></span>
        </label>





        <div className="background"></div>
        <nav className={`nav ${isMobileMenuOpen ? "active" : ""}`}>
          <ul className="list">
            <li className="item">
              <button className="ButtonWithDrop" onClick={handleDropdownToggle}>
                <span>About Us</span> <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 384 512"
                >
                  <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                </svg>
              </button>
              <div
                className={`MobaboutDropdown ${isDropdownOpen ? "open" : ""} `}
              >
                <ul>
                  <li>
                    <div className="aboutItemdropcontent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 384 512"
                      >
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>
                      <MyButton
                        RouteLink="/leadership"
                        text="Our Leadership"
                        FontSize="2rem"
                        FontWeight="600"
                        Color="#797979"
                        handleClick={toggleMobileMenu}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="aboutItemdropcontent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 384 512"
                      >
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>
                      <MyButton
                        RouteLink="/whoweare"
                        text="Who We Are"
                        FontSize="2rem"
                        FontWeight="600"
                        Color="#797979"
                        handleClick={toggleMobileMenu}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </li>

            <li className="item">
              <button className="ButtonWithDrop" onClick={handleDropdownToggle2}>
                <span>Solutions</span> <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 384 512"
                >
                  <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                </svg>
              </button>

              <div
                className={`MobSolutionDropdown ${isDropdownOpen2 ? "open" : ""
                  } `}
              >
                <ul>
                  <li>
                    <div className="aboutItemdropcontent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 384 512"
                      >
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>
                      <MyButton
                        RouteLink="/iot"
                        text="Internet of Things"
                        FontSize="2rem"
                        FontWeight="600"
                        Color="#797979"
                        handleClick={toggleMobileMenu}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="aboutItemdropcontent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 384 512"
                      >
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>
                      <MyButton
                        RouteLink="/satellite_communications"
                        text="Satellite Communications"
                        FontSize="2rem"
                        FontWeight="600"
                        Color="#797979"
                        handleClick={toggleMobileMenu}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </li>

            {/* Media center tab for mobile devices */}

            <li className="item">
              <button className="ButtonWithDrop" onClick={handleDropdownToggle3}>
                <span>Media Center</span> <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 384 512"
                >
                  <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                </svg>
              </button>

              <div className={`MobMediaDropdown ${isDropdownOpen3 ? "open" : ""} `}>
                <ul>
                  <li>
                    <div className="aboutItemdropcontent">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 384 512"
                      >
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>
                      <MyButton
                        RouteLink="/our_news"
                        text="Our News"
                        FontSize="2rem"
                        FontWeight="600"
                        Color="#797979"
                        handleClick={toggleMobileMenu}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </li>

            <li className="item">
              <MyButton
                RouteLink="/careers"
                text="Careers"
                FontSize="3.5rem"
                FontWeight="600"
                Color="white"
                handleClick={toggleMobileMenu}
              />
            </li>
            <li className="item">
              <MyButton
                RouteLink="/contactus"
                text="Contact Us"
                FontSize="3.5rem"
                FontWeight="600"
                Color="white"
                handleClick={toggleMobileMenu}
              />
            </li>

          </ul>
        </nav>
      </div>
    </div>
  );
}
