import { createContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
      const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

      
    
  return (
    <GlobalContext.Provider
      value={{
        isMobileMenuOpen,
        setMobileMenuOpen 
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
