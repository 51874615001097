import React from "react";
import "../Styles/PressRelease.css";
import { Helmet } from "react-helmet";
import Navbar from "../../../Components/Navbar/Navbar";
import Text from "../../../Components/Text/Text";
import ImageComp from "../../../Components/ImageComp/ImageComp";
import MyButton from "../../../Components/Button/Button";
import Footer from "../../../Components/Footer/Footer";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";
import "../Styles/MousSigningCeremony.css";

export default function InotechAndDynasysCombine() {
  return (
    <div className="PressRelease">
      <Helmet>
        <title>Inotech and DynaSys Combine Forces</title>
      </Helmet>

      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            text="Media Center   <span>/</span>"
            text1="  Our News"
            // RouteLink="/our_news/hamid_nawaz_press_release"
            RouteLink1="/our_news"
            Color1="#6C6C6C"
            Color2="black"
          />
          <div className="PressReleaseBody">
            <div className="MousSigningCeremonyPress">
              <div className="PressReleaseBodyTopText">
                <Text
                  text="Inotech and DynaSys Combine Forces to Commercialize IoT in Pakistan"
                  FontWeight="900"
                  FontSize={"3.5rem"}
                  Margin="50px 0px 0px 0px"
                />
                <Text
                  text="Tue 06 Feb, 2024"
                  FontWeight="500"
                  Color="#808080"
                  FontSize={"1.8rem"}
                  font
                  Margin="20px 0px 50px 0px"
                />
              </div>
              <div className="MousCeremonyContent">
                <Text text="<span>Islamabad, February 6, 2024:</span> Inotech Solutions (Inotech) and DynaSys Networks (DynaSys) have signed a Memorandum of Understanding (MoU) during a ceremony held at the National Incubation Center for Aerospace Technologies (NICAT) in Rawalpindi, Pakistan." />
                <Text
                  Margin="30px 0px 30px 0px"
                  text="The signing ceremony was jointly chaired by Inotech’s Wasif Rehman, General Manager, Corporate and Ali Akhtar, Founder and CEO, DynaSys Networks. The ceremony was also attended by senior officials from both organizations, along with the leadership of NICAT."
                />
                <Text
                  Margin="30px 0px 30px 0px"
                  text="The MoU on The Internet of Things (IoT) establishes the technical and commercial framework for the integration of DynaSys’ IoT platform and end-user devices with Inotech’s infrastructure, including data center(s) and transmission networks. This would culminate in a fully assimilated and first of its kind IoT service that would be commercially available to Inotech’s customers, particularly in the public and defense spheres."
                />
                <Text
                  Margin="30px 0px 30px 0px"
                  text="During the ceremony, Wasif Rehman, General Manager, Corporate said, “Inotech specializes in IT
                  system integration, professional services, and software solutions. Our clientele spans across
                  Government, Semi-Government, Defense, and Private Sectors. We already excel in ERP Solutions,
                  Data Centre Management, Cloud Computing and Health Care. Through this innovative partnership
                  with DynaSys, we are adding IoT solutions and services to our portfolio. IoT is undoubtedly becoming
                  a firm reality in Pakistan and with DynaSys’ support, we’re working to establish our position in this
                  space.”"
                />
                <Text
                  Margin="30px 0px 30px 0px"
                  text="On the occasion, Ali Akhtar, Founder and CEO of DynaSys Networks, said, “ALEF, our IoT platform is
                    the most sophisticated, powerful, and diverse platform available in Pakistan today. With scores of
                    ready-to-deliver use cases and a marvelous roadmap to match, we’re thoroughly excited that Inotech
                    has seen the unique value that we are bringing to their IoT ambitions. In the months ahead, we’ll be
                    working hand in glove with Inotech to identify their end customer problems and deliver the solutions
                    that materialize their business case.”"
                />
              </div>
              <div className="MousCeremonyPressImage">
                <ImageComp
                  Src="/Images/inotech-dynasysPressRelease.webp"
                  Width="auto"
                  Margin="50px 0px 0px 0px"
                />
                <div className="MousCeremonyPressImageCaption inotechCap">
                  <Text
                    Margin="50px 0px 30px 0px"
                    FontSize="1.2rem"
                    text="Seated: Ali Akhtar, Founder and CEO of DynaSys Networks (Right), Wasif Rehman, GM corporate Inotech (Left), accompanied by officials of DynaSys and Inotech."
                  />
                </div>
              </div>

              <div className="Press_Release_bottom">
                <div className="SiteLinkSec">
                  <Text
                    text="For any inquiries, please reach out at"
                    Margin="0px 2px 0px 0px"
                    FontSize="1.8rem"
                  />
                  <MyButton
                    RouteLink="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@dynasysnetworks.com"
                    FontSize="1.8rem"
                    text="info@dynasysnetworks.com"
                    FontWeight="400"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="borderSec">
        <div class="border"></div>
      </div>
        
    </div>
  );
}
