import IotPagesHeroSection from "../../Components/IotPagesHeroSection";
import IotSolutionSlider from "../../Components/IotPagesSlider/IotPagesSlider";
import "./IotPages.css";
import HowItWorkSection from "../../Components/HowItWorkSection";
import CommunicationTechnology from "../../Components/CommunicationTechnology";
import IotPagesSolutions from "../../Components/IotPagesSolutions";
import DynasysPlatform from "../../Components/DynasysPlatform";
import ComprehensiveTechnology from "../../Components/ComprehensiveTechnology";

export default function SmartSecurity() {
  const teraMonitorSliderData = [
    {
      SliderLink: "#intelligent_security",
      TerMonitorSolutionImage:
        "/Images/IotPagesImages/Intelligent Security.webp",
      TerMonitorSolutionHead: "Intelligent    <br> Security",
      TerMonitorSolutionDetail:
        "Our solution integrates with crucial security applications, including intrusion systems and video management systems, to provide quick response and maintain the integrity of your business environment.",
    },
    {
      SliderLink: "#intelligent_safety",
      TerMonitorSolutionImage: "/Images/IotPagesImages/Intelligent Safety.webp",
      TerMonitorSolutionHead: "Intelligent   <br>  Safety",
      TerMonitorSolutionDetail:
        "By integrating with key safety applications, including fire monitoring systems, SmartSentry Patrols provides immediate alerts in the event of any safety incidents. This swift response ensures risk mitigation and reinforces the safety of your assets and personnel.",
    },
  ];

  return (
    <div className="SmartSentry">
      {/* HERO SECTION COMPONENT  */}
      <IotPagesHeroSection
        PageTitle={"IoT - SmartSentry"}
        BannerImage={"/Images/IotPagesImages/SmartSecurity.webp"}
        HeroHeading={"SmartSentry"}
        HeroText={
          "SmartSentry is a system made to seamlessly centralize incident and alarm management for all things IoT, while also integrating intelligent security and safety systems.         "
        }
      />

      {/* HOW IT WORK SECTION  */}
      <HowItWorkSection
        WorkImage1={"/Images/IotPagesImages/urbanEfficiency.svg"}
        WorkImage2={"/Images/IotPagesImages/DecisionMaking.svg"}
        WorkImage3={"/Images/IotPagesImages/ElevatedSecurity.svg"}
        Work1={"Operational Excellence"}
        Work2={"Improved Decision Making"}
        Work3={"Enhanced Safety and Securty"}
        WorkDetails={
          "SmartSentry is a system made to seamlessly centralize incident and alarm management for all things IoT, while also integrating intelligent security and safety systems. With SmartSentry, you can experience a heightened level of control, security, and safety within your business."
        }
      />

      {/* Solution Included section  */}
      <div className="IotPagesSliderBg">
        <div className="IotPagesSlider">
          <p className="SolutionHead">Solutions Included</p>
          <IotSolutionSlider
            teraMonitorSliderData={teraMonitorSliderData}
            slidesPerView={2}
          />
        </div>
      </div>

      {/* COMMUNICATION TECHNOLOGY  */}
      <CommunicationTechnology />

      {/* IOT PAGE SOLUTIONS */}
      <IotPagesSolutions
        CatalogueLink={"new-catalogues/Intelligent Security.pdf"}
        Solution_Id={"intelligent_security"}
        SolutionImageLeft={
          "/Images/IotPagesImages/Intelligent SecuritySolution.webp"
        }
        SolutioHeading={"Intelligent    <br> Security"}
        SolutionDetails={
          "Our Intelligent Security system extends its features to include smart intrusion devices such as motion detectors, glass break sen- sors, smart SOS buttons and more. These additions ensure that your premises remain under constant surveillance, and any unauthorized intrusion can be quickly detected and managed."
        }
      />
      <IotPagesSolutions
        CatalogueLink={"new-catalogues/Intelliget Safety.pdf"}
        Solution_Id={"intelligent_safety"}
        SolutioHeading={"Intelligent <br> Safety"}
        SolutionDetails={
          "Intelligent Safety’s fire monitoring capability represents a significant step forward in safety management. With real-time fire and leak detection and automatic alerts, you can take immediate action to mitigate risks and protect people and property."
        }
        SolutionImageRight={
          "/Images/IotPagesImages/Intelligentsafetysolution.webp"
        }
      />

      {/* DYNASYS PLATFORM  */}
      <DynasysPlatform />

      {/* COMPREHENSIVE TECHNOLOGY  */}
      <ComprehensiveTechnology />
    </div>
  );
}
