import IotPagesHeroSection from "../../Components/IotPagesHeroSection";
import IotSolutionSlider from "../../Components/IotPagesSlider/IotPagesSlider";
import "./IotPages.css";
import HowItWorkSection from "../../Components/HowItWorkSection";
import CommunicationTechnology from "../../Components/CommunicationTechnology";
import IotPagesSolutions from "../../Components/IotPagesSolutions";
import DynasysPlatform from "../../Components/DynasysPlatform";
import ComprehensiveTechnology from "../../Components/ComprehensiveTechnology";

export default function Dynalock() {
  const teraMonitorSliderData = [
    {
      TerMonitorSolutionImage: "/Images/IotPagesImages/smartLock.webp",
      TerMonitorSolutionHead: "Smart <br> Locks",
      TerMonitorSolutionDetail:
        "Secure your assets with smart locks that offer <br> keyless access and real-time monitoring.",
        SliderLink:"#smart_locks",
    },
    {
      TerMonitorSolutionImage: "/Images/IotPagesImages/SmartParkingLock.webp",
      TerMonitorSolutionHead: "Smart Parking <br> Locks",
      TerMonitorSolutionDetail:
        "Streamline parking management with intelligent locks, preventing unauthorized use of parking spaces and enhancing security.",
        SliderLink:"#smart_parking_locks",

      },
  ];

  return (
    <div className="Dynalock">

      {/* HERO SECTION COMPONENT  */}
      <IotPagesHeroSection
      PageTitle={"IoT | Dynalock"}
        BannerImage={"/Images/IotPagesImages/DynalockBanner.png"}
        HeroHeading={"DynaLocks"}
        HeroText={
          "DynaLocks is an IoT-driven security and access control system that effortlessly fuses connected devices with cloud infrastructure to amplify security measures."
        }
      />

      {/* HOW IT WORK SECTION  */}
      <HowItWorkSection
        WorkImage1={"/Images/IotPagesImages/EnhancedSecurity.svg"}
        WorkImage2={"/Images/IotPagesImages/improveAccountability.svg"}
        WorkImage3={"/Images/IotPagesImages/optimizedUtilization.svg"}
        Work1={"Enhanced Security"}
        Work2={"Improved Accountability"}
        Work3={"Optimized Utilization"}
        WorkDetails={
          "DynaLocks is an IoT-driven security and access control system that effortlessly fuses connected devices with cloud infrastructure to amplify security measures. It supports diverse authentication methods and offers remote management coupled with real-time monitoring."
        }
      />

      {/* Solution Included section  */}
      <div className="IotPagesSliderBg">
        <div className="IotPagesSlider">
          <p className="SolutionHead">Solutions Included</p>
          <IotSolutionSlider
            teraMonitorSliderData={teraMonitorSliderData}
            slidesPerView={2}
          />
        </div>
      </div>

      {/* COMMUNICATION TECHNOLOGY  */}
      <CommunicationTechnology />

      {/* IOT PAGE SOLUTIONS */}
      <IotPagesSolutions
        CatalogueLink={"new-catalogues/SmartLock.pdf"}
        SolutionImageLeft={"/Images/IotPagesImages/SmartLockSolution.webp"}
        SolutioHeading={"Smart <br> Locks"}
        SolutionDetails={
          "Smart Locks are a technological leap from traditional security methods. A distinct offering from DynaLocks, these padlocks utilize cutting-edge BLE and 4G technologies to offer a level of access control that is both easy to manage and highly secure."}
        Solution_Id={"smart_locks"}
      />
      <IotPagesSolutions
       CatalogueLink={"new-catalogues/SmartParking Locks.pdf"}
      Solution_Id={"smart_parking_locks"}
        SolutioHeading={"Smart Parking<br>Locks"}
        SolutionDetails={
          "As urban spaces grow denser and parking becomes a premium commodity, the need for advanced, secure, and easy-to-manage parking solutions becomes paramount. Our Smart Parking Locks employ BLE and 4G technologies, offering superior control and provides an additional layer of security for your vehicle parking."
        }
        SolutionImageRight={"/Images/IotPagesImages/SmartParkingLockSolution.webp"}
      />

      {/* DYNASYS PLATFORM  */}
      <DynasysPlatform />

      {/* COMPREHENSIVE TECHNOLOGY  */}
      <ComprehensiveTechnology />
    </div>
  );
}
