import React from "react";
import Text from "../../../../Components/Text/Text";
import MyButton from "../../../../Components/Button/Button";
import ImageComp from "../../../../Components/ImageComp/ImageComp";
import HeaderSection from "../../../../Components/HeaderSection/HeaderSection";

export default function EachPressReleaseComponent({
  PressHeading,
  PressDate,
  PressDetails,
  PressImage,
  PressImageWidth,
  PressImageCaption,
  ContactOne,
  ContactOneName,
  ContactOneEmail,
  ContactOnePhone,
  ContactOneEmailLink,
  ContactTwo,
  ContactTwoName,
  ContactTwoEmail,
  ContactTwoPhone,
  ContactTwoEmailLink,
  OldContactNameOne,
  OldContactOneEmailLink,
  OldContactTwoEmailLink,
  OldContactNameTwo,
  ContactUsHeading
}) {
  return (
    <>
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            text="Media Center   <span>/</span>"
            text1="  Our News"
            RouteLink1="/our_news"
            Color1="#6C6C6C"
            Color2="black"
          />
          <div className="PressReleaseBody">
            <div className="MousSigningCeremonyPress">
              <div className="PressReleaseBodyTopText">
                <Text
                  text={PressHeading}
                  FontWeight="900"
                  FontSize={"3.5rem"}
                  font
                  Margin="50px 0px 0px 0px"
                />
                <Text
                  text={PressDate}
                  FontWeight="500"
                  Color="#808080"
                  FontSize={"1.8rem"}
                  font
                  Margin="20px 0px 50px 0px"
                />
              </div>
              <div className="MousCeremonyContent">
                <Text text={PressDetails} />
              </div>
              <div className="PressReleaseImage">
                <ImageComp
                  Src={PressImage}
                  Width={PressImageWidth}
                  Margin="80px 0px 0px 0px"
                  Radius={"0px"}
                />
              </div>
              {PressImageCaption && (
                <div className="MousCeremonyPressImageCaption">
                  <Text
                    Margin="50px 0px 30px 0px"
                    FontSize="1.2rem"
                    text={PressImageCaption}
                  />
                </div>
              )}

              {/* CONTACT DETAILS  */}
              {
                ContactUsHeading && 
                <div className="Press_Release_bottom">
                <div className="SiteLinkSec">
                  <Text
                    text="For additional information, please contact the PR team:"
                    Margin="0px 2px 0px 0px"
                    FontSize="1.8rem"
                  />
                </div>
              </div>
              }

              

              <div className="PressReleaseTextAfterImg">
                {ContactOne && (
                  <>
                    <Text
                      Margin={`${ContactUsHeading ? "30px 0px 20px 0px" : "80px 0px 20px 0px" }`}
                      FontWeight={"800"}
                      text={ContactOne}
                    />
                    <Text text={ContactOneName} />
                    <div className="Azercosmos TextWithLink2">
                      <Text text=" (Email)" Margin={"0px 1rem 0px 0rem"} />
                      <MyButton
                        RouteLink={ContactOneEmailLink}
                        FontSize="1.8rem"
                        text={ContactOneEmail}
                        FontWeight="400"
                      />
                    </div>
                    <div className="Azercosmos TextWithLink2">
                      <Text text=" (Phone)" Margin={"0px 0.6rem 0px 0rem"} />
                      <Text text={ContactOnePhone} />
                    </div>
                  </>
                )}

                {ContactTwo && (
                  <>
                    <Text
                      Margin="50px 0px 20px 0px"
                      FontWeight={"800"}
                      text={ContactTwo}
                    />
                    <Text text={ContactTwoName} />
                    <div className="Azercosmos TextWithLink2">
                      <Text text=" (Email)" Margin={"0px 1rem 0px 0rem"} />
                      <MyButton
                        RouteLink={ContactTwoEmailLink}
                        FontSize="1.8rem"
                        text={ContactTwoEmail}
                        FontWeight="400"
                      />
                    </div>
                    <div className="Azercosmos TextWithLink2">
                      <Text text=" (Phone)" Margin={"0px 0.6rem 0px 0rem"} />
                      <Text text={ContactTwoPhone} />
                    </div>
                  </>
                )}
              </div>

              {/* OLD CONTACT dESIGN  */}
              {OldContactNameOne && (
                <div className="Press_Release_bottom">
                  <div className="DynasysPortnet SiteLinkSec">
                    <Text
                      text="For any inquiries, please reach out at:"
                      Margin="0px 2px 0px 0px"
                      FontSize="1.8rem"
                    />
                    <ul>
                      <li>
                        <Text
                          text={OldContactNameOne}
                          Margin="0px 2px 0px 0px"
                          FontSize="1.8rem"
                        />
                        <MyButton
                          RouteLink={OldContactOneEmailLink}
                          FontSize="1.8rem"
                          text="info@dynasysnetworks.com"
                          FontWeight="400"
                        />
                      </li>
                      <li>
                        <Text
                          text={OldContactNameTwo}
                          Margin="0px 2px 0px 0px"
                          FontSize="1.8rem"
                        />
                        <MyButton
                          RouteLink={OldContactTwoEmailLink}
                          FontSize="1.8rem"
                          text="info@pronet-tech.net"
                          FontWeight="400"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div class="borderSec">
        <div class="border"></div>
      </div>
    </>
  );
}
