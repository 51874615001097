import React from "react";
import Navbar from "../../../../Components/Navbar/Navbar";
import HeaderSection from "../../../../Components/HeaderSection/HeaderSection";
import Text from "../../../../Components/Text/Text";
import ImageComp from "../../../../Components/ImageComp/ImageComp";
import MyButton from "../../../../Components/Button/Button";

export default function EachPressRelease({
  PressReleaseHeadLine,
  PresReleaseDate,
  PressReleaseHeader,
  PressReleaseDetailsText1,
  PressReleaseDetailsText2,
  PressReleaseDetailsText3,
  PressReleaseImage
}) {
  return (
    <div className="EachPressRelease">
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            text="Media Center   <span>/</span>"
            text1="  Our News"
            // RouteLink="/our_news/hamid_nawaz_press_release"
            RouteLink1="/our_news"
            Color1="#6C6C6C"
            Color2="black"
          />
          <div className="PressReleaseBody">
            <div className="PressReleaseBodyTopText">
              <Text
                text={PressReleaseHeadLine}
                FontWeight="900"
                FontSize={"3.5rem"}
                font
                Margin="50px 0px 50px 0px"
              />
              <Text
                text={PresReleaseDate}
                FontWeight="500"
                Color="#808080"
                FontSize={"1.8rem"}
                font
                Margin="50px 0px 20px 0px"
              />
              <Text
                text={PressReleaseHeader}
                FontSize={"3.2rem"}
                Margin="0px 0px 50px 0px"
                Padding="0px 150px 0px 0px"
              />
            </div>

            <div className="PressRelease_Img_Sec">
              <div className="Right_Sec_Mob">
                <ImageComp Src={PressReleaseImage} Width="100%" />
              </div>
              <div className="Left_Sec">
                <Text text={PressReleaseDetailsText1}  FontSize="2.2rem" />
              </div>
              <div className="Right_Sec">
                <ImageComp Src={PressReleaseImage} Width="100%" />
              </div>
            </div>
            <Text text={PressReleaseDetailsText2} />
            <Text text={PressReleaseDetailsText3} Margin="30px 0px 0px 0px" />
            <div className="Press_Release_bottom">
              <div className="SiteLinkSec">
                <Text
                  text="For any inquiries, please reach out at"
                  Margin="0px 2px 0px 0px"
                  FontSize="1.8rem"
                />
                <MyButton
                  RouteLink="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@dynasysnetworks.com"
                  FontSize="1.8rem"
                  text="info@dynasysnetworks.com"
                  FontWeight="400"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="borderSec">
        <div class="border"></div>
      </div>
    </div>
  );
}
