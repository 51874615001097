
export default function ComprehensiveTechnology() {
  return (
    <>
<div className="ComprehensivePlatform SolutionWrapper">
        <div className="Solution1">
          <div className="SolitionDetails">
            <p>Comprehensive Platform</p>
            <p>
              See the quality of your air on individual views or live maps.
              Create individual views and invite employees or customers to
              collaborate.
            </p>
            <div className="SolutionProperties">
              <p>
                Send Alerts via SMS and E-Mail
                <br />
                Data-Logging and export
                <br />
                Real-Time data-visualisation
                <br />
                Data Export over REST API or MQTT
              </p>
            </div>
            <div className="BannerButtons">
            <a className="OurPlatformLink" href={"https://myiotworld.garajcloud.com/auth/root-login"} target="blank">Our Platform</a>

            </div>
          </div>
          <div className="SolitionImage">
            <img
              src="/Images/IotPagesImages/ComprehensivePlatform.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="borderSec"><div class="border"></div></div>
    </>
    

  )
}
