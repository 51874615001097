import "./SatelliteCommunications.css";
import IotPagesHeroSection from "../../Components/IotPagesHeroSection";
import Text from "../../Components/Text/Text";
import IotSolutionSlider from "../../Components/IotPagesSlider/IotPagesSlider";
import IotPagesSolutions from "../../Components/IotPagesSolutions";

export default function SatelliteCommunications() {
  const teraMonitorSliderData = [
    {
      TerMonitorSolutionImage:
        "/Images/SatelliteCommunicationsImages/FlyawayKu-band.webp",
      TerMonitorSolutionHead: "0.9m Flyaway Ku-band",
      SliderLink:"/0.9m_flyaway_ku_band"
    },
    {
      TerMonitorSolutionImage:
        "/Images/SatelliteCommunicationsImages/CompositeSimple.webp",
      TerMonitorSolutionHead: "1.2m Fixed Ku-band",
      SliderLink:"/1.2m_fixed_ku_band"

    },
    {
      TerMonitorSolutionImage:
        "/Images/SatelliteCommunicationsImages/SatcomFlyawayAnetnna.webp",
      TerMonitorSolutionHead: "1.2m Flyaway Ku-band",
        SliderLink:"/1.2m_flyaway_ku_band"
    },
  ];

  return (
    <div className="SatelliteCommunications">
      {/* HERO SECTION COMPONENT  */}
      <IotPagesHeroSection
        PageTitle={"Satellite Communications"}
        BannerImage={
          "/Images/SatelliteCommunicationsImages/SatellitePageBanner.webp"
        }
        HeroHeading={"Satellite Communications"}
        HeroText={
          "Experience transnational coverage with our extensive satellite network ensuring connectivity in even the most remote and challenging environments worldwide. Wherever your operations take you, we have you covered. "
        }
      />

      {/* OUR EXPERTISE AND SUPPORT  */}

      <div className="OurExpertiseSupport">
        <div className="OurExpertiseSupportInner">
          <Text
            text={"Our Expertise and Support"}
            FontSize={"2rem"}
            FontWeight={"400"}
            Color={"white"}
          />
          <Text
            Color={"white"}
            FontSize={"2.8rem"}
            FontWeight={"400"}
            Margin={"3rem 0px 7rem 0px"}
            text={
              "We pride ourselves on our deep industry knowledge and technical expertise in satellite services. Our team of experts is dedicated to understanding your unique requirements and providing tailored solutions that meet your business goals.<br> <br>Count on our 24/7 support and the experience of our field engineers, who are deployed globally to ensure the highest level of service."
            }
          />
        </div>
      </div>

      {/* Our Products  */}
      <div className="IotPagesSliderBg SatelliteProduct">
        <div className="IotPagesSlider">
          <p className="SolutionHead">Our Products</p>
          <IotSolutionSlider
            teraMonitorSliderData={teraMonitorSliderData}
            slidesPerView={3}
          />
        </div>
      </div>

      <IotPagesSolutions
      ButtonNone
        SolutionImageLeft={
          "/Images/SatelliteCommunicationsImages/SatelliteCapacity.webp"
        }
        SolutioHeading={"Satellite Capacity and Capabilities"}
        SolutionDetails={
          "With a robust and scalable satellite infrastructure, we deliver high-performance connectivity and data transmission capabilities.<br><br>We are equipped with state-of-the-art technology, providing reliable and secure connections for mission-critical operations.<br><br>From voice and data to video and IoT applications, we empower businesses with the capacity to transmit and receive data efficiently."
        }
      />
      <IotPagesSolutions
      ButtonNone
        SolutionImageRight={
          "/Images/SatelliteCommunicationsImages/SatelliteNetwork.webp"
        }
        SolutioHeading={"Satellite Network Infrastructure"}
        SolutionDetails={
          "Benefit from our vast network infrastructure that combines the power of numerous satellites, teleports, and cutting-edge technology.<br><br>We maintain a diverse range of satellites in our network, strategically positioned to ensure seamless coverage across the globe. Our satellite network is designed to provide exceptional performance, reliability, and scalability for your business needs."
        }
      />
      <IotPagesSolutions
      ButtonNone
        SolutionImageLeft={
          "/Images/SatelliteCommunicationsImages/BenefitsofSatellite.webp"
        }
        SolutioHeading={"Benefits of Satellite Connectivity"}
        SolutionDetails={
          "Reliability is at the core of satellite connectivity, making it an ideal solution for businesses operating in remote or challenging environments.<br><br>With satellite services from DynaSys Networks, trust in robust and consistent connectivity that keeps your operations running smoothly. Enjoy the advantages of global reach, ensuring connectivity wherever your business takes you."
        }
      />
      <div class="borderSec"><div class="border"></div></div>

    </div>
  );
}
