import IotPagesHeroSection from "../../Components/IotPagesHeroSection";
import IotSolutionSlider from "../../Components/IotPagesSlider/IotPagesSlider";
import "./IotPages.css";
import HowItWorkSection from "../../Components/HowItWorkSection";
import CommunicationTechnology from "../../Components/CommunicationTechnology";
import IotPagesSolutions from "../../Components/IotPagesSolutions";
import DynasysPlatform from "../../Components/DynasysPlatform";
import ComprehensiveTechnology from "../../Components/ComprehensiveTechnology";

export default function Umeter() {
  const teraMonitorSliderData = [
    {
      TerMonitorSolutionImage: "/Images/IotPagesImages/GasMetering.webp",
      TerMonitorSolutionHead: "Gas Metering",
      TerMonitorSolutionDetail:
        "Keep an eye on gas usage, detect leaks, and generate bills based on actual consumption.",
        SliderLink:"#gas_metering"
    },
    {
       SliderLink:"#water_metering",
      TerMonitorSolutionImage: "/Images/IotPagesImages/waterMetering.webp",
      TerMonitorSolutionHead: "Water Metering",
      TerMonitorSolutionDetail:
        "Real-time monitoring of water consumption for optimized usage and accurate billing.",
    },
    {
       SliderLink:"#power_metering",
      TerMonitorSolutionImage: "/Images/IotPagesImages/PowerMetering.webp",
      TerMonitorSolutionHead: "Power Metering",
      TerMonitorSolutionDetail:
        "Track electricity consumption, manage energy usage, and ensure accurate billing based on actual consumption.",
    },
  ];

  return (
    <div className="UMeter">
      {/* HERO SECTION COMPONENT  */}
      <IotPagesHeroSection
      PageTitle={"IoT | UMeter"}
        BannerImage={"/Images/IotPagesImages/UmeterBanner.png"}
        HeroHeading={"UMeter"}
        HeroText={
          "The Umeter Application is an advanced IoT solution designed for the comprehensive management of utility consumption."
        }
      />

      {/* HOW IT WORK SECTION  */}
      <HowItWorkSection
        WorkImage1={"/Images/IotPagesImages/Transparency and Accuracy.svg"}
        WorkImage2={"/Images/IotPagesImages/sustainability.svg"}
        WorkImage3={"/Images/IotPagesImages/costEffective.svg"}
        Work1={"Transparency and Accuracy"}
        Work2={"Promoting Sustainability"}
        Work3={"Cost Effective"}
        WorkDetails={
          "The UMeter Application is an advanced IoT solution designed for the comprehensive management of utility consumption. Utilizing smart meters, it enables precise monitoring and control of water, gas, electricity, and thermal energy usage. The application integrates cutting-edge technology for predictive billing and efficient resource management, making it a crucial tool for modern utility management."
        }
      />

      {/* Solution Included section  */}
      <div className="IotPagesSliderBg">
        <div className="IotPagesSlider">
          <p className="SolutionHead">Solutions Included</p>
          <IotSolutionSlider
            teraMonitorSliderData={teraMonitorSliderData}
            slidesPerView={3}
          />
        </div>
      </div>

      {/* COMMUNICATION TECHNOLOGY  */}
      <CommunicationTechnology />

      {/* IOT PAGE SOLUTIONS */}
      <IotPagesSolutions
        CatalogueLink={"new-catalogues/Gas Metering.pdf"}
        Solution_Id={"gas_metering"}
        SolutionImageLeft={"/Images/IotPagesImages/GasMeteringSolution.webp"}
        SolutioHeading={"Gas  <br> Metering"}
        SolutionDetails={
          "UMeter enhances safety through continuous monitoring and instant leak detection, ensuring compliance and reducing risks. It optimizes gas usage by analyzing consumption patterns, leading to energy savings and cost reductions. <br><br> This device quickly detects leaks with IoT sensors and sends immediate alerts for prompt response. Additionally, it offers remote monitoring and control of gas valves, allowing users to shut off supply if leaks are detected or if usage exceeds set limits."
        }
      />
      <IotPagesSolutions 
      CatalogueLink={"new-catalogues/Water Metering.pdf"}
      Solution_Id={"water_metering"}
        SolutioHeading={"Water  <br> Metering"}
        SolutionDetails={
          "The water metering solution of the UMeter Application employs advanced ultrasonic technology to provide precise and real-time data on water consumption. <br><br>It is equipped with features such as leak detection and advanced analytics, enabling users to identify inefficiencies and potential savings. This solution also supports remote reading capabilities, which is crucial for large-scale water management in diverse environments."
        }
        SolutionImageRight={"/Images/IotPagesImages/waterMeteringSolution.webp"}
      />
      <IotPagesSolutions
       CatalogueLink={"new-catalogues/Power Metering.pdf"}
      Solution_Id={"power_metering"}
        SolutioHeading={"Power  <br> Metering"}
        SolutionDetails={
          "In the era of smart cities, monitoring and managing energy usage effectively is key. DynaSys’s advanced electricity metering provides granular insights into power consumption.<br><br>This not only streamlines energy use but also paves the way for a more sustainable and cost-efficient residential infrastructure."
        }
        SolutionImageLeft={"/Images/IotPagesImages/powerMeteringSolution.webp"}
      />

      {/* DYNASYS PLATFORM  */}
      <DynasysPlatform />

      {/* COMPREHENSIVE TECHNOLOGY  */}
      <ComprehensiveTechnology />
    </div>
  );
}
