import { Helmet } from "react-helmet";
import IotPagesHeroSection from "../../Components/IotPagesHeroSection";
import "./IotPages.css";
import HowItWorkSection from "../../Components/HowItWorkSection";
import CommunicationTechnology from "../../Components/CommunicationTechnology";
import IotPagesSolutions from "../../Components/IotPagesSolutions";
import DynasysPlatform from "../../Components/DynasysPlatform";
import ComprehensiveTechnology from "../../Components/ComprehensiveTechnology";

export default function InteliPark() {


  return (
    <div className="IntelliPark">
      {/* HERO SECTION COMPONENT  */}
      <IotPagesHeroSection
      PageTitle={"IoT - IntelliPark"}
        BannerImage={"/Images/IotPagesImages/InteliparkBAnner.webp"}
        HeroHeading={"IntelliPark"}
        HeroText={
          "With bustling residential areas, dynamic office spaces, and diverse retail environments, the need for efficient vehicle entry and exit management has never been greater. "
        }
      />

      {/* HOW IT WORK SECTION  */}
      <HowItWorkSection
        WorkImage1={"/Images/IotPagesImages/EnhancedSecurity.svg"}
        WorkImage2={"/Images/IotPagesImages/Accountability.svg"}
        WorkImage3={"/Images/IotPagesImages/optimizedUtilization.svg"}
        Work1={"Enhanced Security"}
        Work2={"Improved Accountability"}
        Work3={"Optimized Utilization"}
        WorkDetails={
          "With bustling residential areas, dynamic office spaces, and diverse retail environments, the need for efficient vehicle entry and exit management has never been greater. IntelliPark harnesses the power of Advanced Number Plate Recognition (ANPR) to transform conventional access points into state-of-the-art controlled passages, ensuring both convenience and security."
        }
      />

      {/* COMMUNICATION TECHNOLOGY  */}
      <CommunicationTechnology />

      {/* IOT PAGE SOLUTIONS */}
      <IotPagesSolutions
      CatalogueLink={"new-catalogues/IntelliPark.pdf"}
      Solution_Id={"smart_anpr_access_management"}
        SolutionImageLeft={"/Images/IotPagesImages/SmartAnprSolution.webp"}
        SolutioHeading={"Smart ANPR Access <br> Management"}
        SolutionDetails={
          "In modern urban environments, the management of vehicle access to various facilities, gated communities, and parking lots is a complex challenge. Smart ANPR Access Management simplifies this task, providing an efficient and intelligent solution.  <br> <br>Through advanced technology, building managers, and residents can control and monitor access with unprecedented ease, even accommodating temporary vehicle requirements."
        }
      />

      {/* DYNASYS PLATFORM  */}
      <DynasysPlatform />

      {/* COMPREHENSIVE TECHNOLOGY  */}
      <ComprehensiveTechnology />
    </div>
  );
}
