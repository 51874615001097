import React from "react";
import "../Styles/PressRelease.css";
import { Helmet } from "react-helmet";
import Navbar from "../../../Components/Navbar/Navbar";
import Text from "../../../Components/Text/Text";
import ImageComp from "../../../Components/ImageComp/ImageComp";
import MyButton from "../../../Components/Button/Button";
import Footer from "../../../Components/Footer/Footer";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";
import "../Styles/MousSigningCeremony.css";

export default function PtclDynasysJoinHands() {
  return (
    <div className="PressRelease">
      <Helmet>
        <title>PTCL & DynaSys Join Hands</title>
      </Helmet>      
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            text="Media Center   <span>/</span>"
            text1="  Our News"
            // RouteLink="/our_news/hamid_nawaz_press_release"
            RouteLink1="/our_news"
            Color1="#6C6C6C"
            Color2="black"
          />
          <div className="PressReleaseBody">
          <div className="MousSigningCeremonyPress">
          <div className="PressReleaseBodyTopText">
              <Text
                text="PTCL & DynaSys Join Hands to Take IoT & Satellite Communications to the Next Level"
                FontWeight="900"
                FontSize={"3.5rem"}
                font
                Margin="50px 0px 0px 0px"
              />
              <Text
                text="Tue 12 Sep, 2023"
                FontWeight="500"
                Color="#808080"
                FontSize={"1.8rem"}
                font
                Margin="20px 0px 50px 0px"
              />
            </div>
            <div className="MousCeremonyContent">
              <Text  text="<span>Islamabad, September 12, 2023: </span> Pakistan Telecommunication Company Limited (PTCL) and DynaSys Networks (DynaSys) have signed two Memoranda of Understandings (MoUs) during a ceremony held at the National Aerospace Science and Technology Park (NASTP) in Rawalpindi." />
              <Text Margin="30px 0px 30px 0px" text="The signing ceremony was jointly chaired by Aamer Ejaz, Group Vice President, Carrier Services, PTCL & Ufone 4G and Ali Akhtar, Founder and CEO, DynaSys Networks. The ceremony was also attended by senior officials from both organizations, along with the leadership of NASTP." />
              <Text  Margin="30px 0px 30px 0px" text="The MoU on Internet of Things (IoT) establishes the technical framework for the integration of DynaSys’ IoT platform and end user devices with PTCL’s Cloud and Cybersecurity infrastructure as well as wired and wireless transmission networks. This would culminate in a fully assimilated and first of its kind IoT service that would be commercially available to customers across Pakistan. The mission statement on IoT reaffirmed by both organizations is to jointly become Pakistan’s largest IoT service provider with the first to one million active end user subscriptions." />
              <Text   text="During the ceremony, Aamer Ejaz, Group Vice President Carrier Services, PTCL & Ufone 4G said, “We are excited to establish a partnership with DynaSys to support the IoT and satcom needs of our business partners. PTCL, a market leader in telecom, is proactively playing its role to bridge the digital divide to bring a positive change in the lifestyle of people. Such partnerships will help achieve that objective by providing futuristic, innovative and cost-effective solutions even in the remotest parts of the country. It will contribute significantly towards stirring the business activity and overall economic growth of the country.”" />

            </div>
            <div className="PressReleaseImage">
              <ImageComp Src="/Images/PTCL DynaSys Press Release Page img2.webp" Width="auto" Margin="50px 0px 0px 0px" />
              <div className="PressReleaseImageCaption" >
              <Text  FontSize="1.2rem" text="Seated: Ali Akhtar, Founder and CEO of DynaSys Networks (Center), Aamer Ejaz, Group Vice President Carrier Services, PTCL & Ufone 4G (Right), Air Cdre. Dr. Tauseef ur Rehman, Project Director (Tech) NASTP (Left) accompanied by officials of DynaSys and PTCL " />
              </div>
            </div>
            <div className="PressReleaseTextAfterImg">
              <Text Margin="80px 0px 0px 0px"  text="On the occasion, Ali Akhtar, Founder and CEO of DynaSys Networks, said, “The framework that DynaSys and PTCL have formally signed today is a giant leap for materializing IoT in Pakistan. The missing piece of the puzzle so far has been the platform which we have now solved. In the months ahead we will be working hand in glove to materialize Pakistan’s first full-fledged IoT service. On satellite communications, it is natural that the talent of both organizations would gravitate towards each other for a high impact market presence. We’re taking solid steps to dominate this space in Pakistan and to spread out to the broader region.”" />
              <Text  Margin="30px 0px 30px 0px" text="The MoU on satellite communications aligns the vast technical and commercial expertise of DynaSys’ team with PTCL’s extensive infrastructure and rich history of satellite services in Pakistan. Through this alliance, both companies not only endeavour to become Pakistan’s largest satellite communications systems integrators and service providers, but also aim to significantly increase market share across the region." />
            </div>
            

            
           


            <div className="Press_Release_bottom">
              <div className="SiteLinkSec">
                <Text
                  text="For any inquiries, please reach out at"
                  Margin="0px 2px 0px 0px"
                  FontSize="1.8rem"
                />
                <MyButton
                  RouteLink="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@dynasysnetworks.com"
                  FontSize="1.8rem"
                  text="info@dynasysnetworks.com"
                  FontWeight="400"
                />
              </div>
            </div>
          </div>
            
          </div>
        </div>
      </div>

      <div class="borderSec">
        <div class="border"></div>
      </div>
        
    </div>
  );
}
