import React from 'react'
import IotLandingSlider from './IotLandingSlider'

export default function IotLanding() {
  return (
    <div>
      <IotLandingSlider />
    </div>
  )
}
